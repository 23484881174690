import styled, {css} from "styled-components";
import {Modal} from "antd";
import { FlutterWaveButton } from 'flutterwave-react-v3';

export const Wrapper = styled(Modal)`
  width: min(80%, 525px);
  //padding: 0 16px;
  
  & p {
    margin-top: 0;
    margin-bottom: 0;
    //margin-block-end: 0;
    //margin-block-start: 0;
  }
  
  & div[class~="ant-modal-content"] {
    padding: 35px 65px;
  }
`;

export const Content = styled.div`
  //width: 100%;
  //padding: 35px 65px;
`;

export const Header = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.374px;
  color: #111317;
  margin-bottom: 30px !important;
`

// export const PayButton = styled(FlutterWaveButton)`
export const PayButton = styled.button`
  height: 75px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  //background: #E0E0E0;
  background-color: rgba(224, 224, 224, 0.2);
  //opacity: 0.2;
  border: 1px solid #C0CCDA;
  border-radius: 20px;
`

export const EmptyKey = styled.p`
  margin-top: 30px;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 16px;
  line-height: 41px;
  text-align: center;
  letter-spacing: 0.374px;
  color: #FF2154;
`
