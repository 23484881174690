import React, { useState } from "react";
import ReactToPrint from "react-to-print";
import { Spin } from "antd";
import {
  BusinessAddressText,
  ClientAddressText,
  ClientLabel,
  ClientNameText,
  Content,
  DateAddress,
  DateAddressRight,
  DateAddressSide,
  DateText,
  Divider,
  DownloadFooter,
  DownloadInvoiceButton,
  Header,
  InvoiceNumber,
  InvoiceStatus,
  Items,
  ItemsTable,
  Logo,
  PayButton,
  PayContainer,
  PrintButton,
  StatusDiv,
  SubtotalDiv,
  SubtotalValue,
  TotalDiv,
  TotalLabel,
  TotalsBox,
  TotalsContainer,
  TotalValue,
  Wrapper,
} from "./style";
import { ReactComponent as CloseIcon } from "../assets/close-circle.svg";
import { ReactComponent as DownloadIcon } from "./assets/download.svg";
import { TableText } from "../../pages/Invoices/style";
import downloadToPdf from "../../utils/download";

export type InvoiceItem = {
  amount: string;
  date_created: string;
  date_modified: string;
  item_description: string;
  rate: string;
  id: number;
  invoice: number;
  quantity: number;
};

interface InvoiceModalProps {
  isModalOpen: boolean;
  status: "Overdue" | "Pending" | "Paid";
  invoiceId: string;
  issuedDate: string;
  dueDate: string;
  clientName: string;
  clientAddress: string;
  businessName: string;
  businessAddress: string;
  businessLogoUrl: string;
  subtotal: string;
  total: string;
  vat: number;
  items: InvoiceItem[];
  onCloseModal: () => void;
  onOpenPayModal: () => void;
}

const columns = [
  {
    title: "QTY",
    dataIndex: "quantity",
    key: "quantity",
    render: (text: string) => <TableText>{text}</TableText>,
  },
  {
    title: "ITEM DESCRIPTION",
    dataIndex: "item_description",
    key: "description",
    render: (text: string) => <TableText>{text}</TableText>,
  },
  {
    title: "RATE",
    dataIndex: "rate",
    key: "rate",
    render: (text: string) => <TableText>{text}</TableText>,
  },
  {
    title: "AMOUNT",
    dataIndex: "amount",
    key: "amount",
    render: (text: string) => <TableText>&#8358;{text}</TableText>,
  },
];

const InvoiceModal = ({
  businessLogoUrl,
  onCloseModal,
  clientName,
  clientAddress,
  businessAddress,
  dueDate,
  invoiceId,
  issuedDate,
  businessName,
  isModalOpen,
  onOpenPayModal,
  status,
  subtotal,
  vat,
  total,
  items,
}: InvoiceModalProps) => {
  const receiptRef = React.useRef();
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadPdf = async () => {
    setIsLoading(true);
    await downloadToPdf(receiptRef, "Invoice");
    setIsLoading(false);
    // onCancel();
  };

  return (
    <Wrapper
      title=""
      open={isModalOpen}
      footer={null}
      width={"min(80%, 780px)"}
      style={{ borderRadius: 30 }}
      maskStyle={{ backgroundColor: "rgba(29, 30, 44, 0.62)" }}
      closeIcon={<CloseIcon />}
      onCancel={onCloseModal}
    >
      <Spin spinning={isLoading}>
        <Content ref={receiptRef}>
          <Header>
            <Logo src={businessLogoUrl} />
            <InvoiceNumber>INV-{invoiceId}</InvoiceNumber>
          </Header>
          <DateAddress>
            <DateAddressSide>
              <BusinessAddressText>
                {businessAddress || "--"}
              </BusinessAddressText>
              <DateText>{issuedDate || "--"}</DateText>
              <ClientLabel>Due date</ClientLabel>
              <DateText>{dueDate || "--"}</DateText>
            </DateAddressSide>
            <DateAddressRight>
              <ClientLabel>Billed to</ClientLabel>
              <ClientNameText>{clientName || "--"}</ClientNameText>
              <ClientAddressText>{clientAddress || "--"}</ClientAddressText>
            </DateAddressRight>
          </DateAddress>
          <StatusDiv>
            <ClientLabel>Status</ClientLabel>
            <InvoiceStatus $status={status}>{status}</InvoiceStatus>
          </StatusDiv>
          <Divider />
          <Items>
            <ItemsTable
              columns={columns}
              dataSource={items || []}
              pagination={false}
            />
          </Items>
          <TotalsContainer>
            <TotalsBox>
              <SubtotalDiv>
                <TotalLabel>Sub Total</TotalLabel>
                <SubtotalValue>&#8358;{subtotal}</SubtotalValue>
              </SubtotalDiv>
              <SubtotalDiv>
                <TotalLabel>VAT(10%)</TotalLabel>
                <SubtotalValue>&#8358;{vat}</SubtotalValue>
              </SubtotalDiv>
              <TotalDiv>
                <TotalLabel>Total(NGN)</TotalLabel>
                <TotalValue>&#8358;{total}</TotalValue>
              </TotalDiv>
            </TotalsBox>
          </TotalsContainer>
        </Content>

        <PayContainer>
          {["Overdue", "Pending"].includes(status) ? (
            <PayButton onClick={onOpenPayModal}>Make Payment</PayButton>
          ) : (
            <DownloadFooter>
              <DownloadInvoiceButton type="button" onClick={handleDownloadPdf}>
                <DownloadIcon />
                <span>Invoice</span>
              </DownloadInvoiceButton>
              <ReactToPrint
                trigger={() => (
                  <PrintButton onClick={handleDownloadPdf}>Print</PrintButton>
                )}
                content={() => receiptRef.current}
              />
            </DownloadFooter>
          )}
        </PayContainer>
      </Spin>
    </Wrapper>
  );
};

export default InvoiceModal;
