import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { MutableRefObject } from "react";

const downloadToPdf = async (docRef: MutableRefObject<any>, downloadName: string) => {
    try {
        const canvas = await html2canvas(docRef.current, { scale: Number("5") })
        const imgData = canvas.toDataURL("image/png", 1.0);
        const doc = await new jsPDF({
            format: "a4",
            orientation: "p",
            unit: "px",
            compress: true,
        });
        await doc.setFillColor("#fffbf7");
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        const widthRatio = pageWidth / canvas.width;
        const heightRatio = pageHeight / canvas.height;
        const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

        const canvasWidth = canvas.width * ratio;
        const canvasHeight = canvas.height * ratio;

        const marginX = (pageWidth - canvasWidth) / 2;
        const marginY = (pageHeight - canvasHeight) / 2;

        await doc.addImage(
            imgData,
            "JPEG",
            marginX,
            marginY,
            canvasWidth,
            canvasHeight
        );
        await doc.save(downloadName + ".pdf");
    } catch (e) {

        return e;
    }
};

export default downloadToPdf;
