import React, { useEffect, useState } from "react";
import { message } from 'antd';
import InvoiceModal, {InvoiceItem} from "../../components/InvoiceModal";
import {
    BackNav, Body,
    Cards,
    Container,
    HistoryBody,
    HistoryContainer,
    HistoryTable,
    HistoryTitle, InvoiceStatus,
    Left,
    Nav,
    NavTitle,
    TableText
} from "./style";
import InvoiceCard from "./components/InvoiceCard";
import {ReactComponent as Back} from "../../components/assets/back.svg";
import {fetchInvoice, fetchInvoiceTotal, fetchPaymentKeys} from "./invoiceSlice";
import {useAppDispatch, useAppSelector} from "../../app/hook";

import dayjs from "dayjs";
import InvoicePayModal from "../../components/InvoicePayModal";
import StripeModal from "../../components/StripeModal";
import {Spin} from "antd";


const columns = [
    {
        title: 'Invoice Name',
        dataIndex: 'description',
        key: 'name',
        render: (text: string) => (<TableText>{text?.toLowerCase()}</TableText>)
    },
    {
        title: 'Date',
        dataIndex: 'issued_on',
        key: 'date',
        render: (text: string) => (<TableText>{dayjs(text).format('DD/MM/YYYY')}</TableText>)
    },
    {
        title: 'Inv No',
        dataIndex: 'invoice_number',
        key: 'invoice_number',
        render: (text: string) => (<TableText>#{text}</TableText>)
    },
    {
        title: 'Amount',
        dataIndex: 'total',
        key: 'type',
        render: (text: string) => (
                <TableText>&#8358;{Number(text)?.toFixed(2)}</TableText>)
    },
    {
        title: 'Post Status',
        dataIndex: 'status',
        key: 'status',
        render: (text: "Overdue" | "Pending" | "Paid") => (
            <InvoiceStatus $status={text}>
                <div/>
                <p>{text}</p>
            </InvoiceStatus>
        ),
    },
]

export type Invoice = {
    status: "Overdue" | "Pending" | "Paid"
    description: string;
    currency: string;
    issued_on: string;
    due_date: string;
    client: {
        client_business_name: string;
        client_address: string;
        fullname: string;
        client_email: string;
    }
    extra_details: {
        business_address: string;
        business_logo: string;
        business_name: string;
    }
    invoice_number: string;
    sub_total: string;
    total: string;
    vat: number;
    id: number;
    items: InvoiceItem[];
};


const Invoices = () => {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.login);
    const { invoices, totalPending, totalPaid, status } = useAppSelector((state) => state.invoice);
    const [showModal, setShowModal] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
    const [showPayModal, setShowPayModal] = useState(false);
    const [showStripeModal, setShowStripeModal] = useState(false);

    const [messageApi, contextHolder] = message.useMessage();

    const getAllInvoices = async () => {
        await dispatch(fetchInvoiceTotal());
        await dispatch(fetchInvoice());

        // fetch payment gateways
        await dispatch(fetchPaymentKeys());
    };

    const onSelectInvoice = (invoice: any) => {
        setSelectedInvoice(invoice)
        setShowModal(true)
    }

    useEffect(() => {
        if (user && user?.id) {
            getAllInvoices();
        }
    }, [user]);

    const onCloseModal = () => {
        setShowModal(false);
    };

    const onClosePayModal = () => {
        setShowPayModal(false);
        onCloseModal()
    };

    const onOpenPayModal = () => {
        setShowPayModal(true);
        setShowModal(false);
    };

    const onCloseStripeModal = () => {
        setShowStripeModal(false);
        setShowPayModal(false);
        onCloseModal()
    };

    const onOpenStripeModal = () => {
        setShowStripeModal(true);
        setShowPayModal(false);
        setShowModal(false);
    };

    return(
        <Container>
            {contextHolder}
            <Spin spinning={status === 'loading'}>
            <Body>
            <Left>
                <Nav>
                    <BackNav to='/dashboard'><Back/></BackNav>
                    <NavTitle>Invoices</NavTitle>
                </Nav>
                <Cards>
                    <InvoiceCard title='Total Pending' amount={totalPending}/>
                    <InvoiceCard title='Total Paid' amount={totalPaid}/>
                </Cards>
            </Left>
            <HistoryContainer>
                <HistoryTitle>Invoice History</HistoryTitle>
                <HistoryBody>
                    <HistoryTable
                        columns={columns}
                        dataSource={invoices || []}
                        pagination={false}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: (event) => {onSelectInvoice(record)}
                            };
                        }}
                    />
                </HistoryBody>
            </HistoryContainer>
            {selectedInvoice && (

                    <InvoiceModal
                        isModalOpen={showModal}
                        onCloseModal={onCloseModal}
                        status={selectedInvoice.status}
                        invoiceId={selectedInvoice.invoice_number}
                        issuedDate={selectedInvoice.issued_on}
                        dueDate={selectedInvoice.due_date}
                        clientName={selectedInvoice.client.fullname}
                        clientAddress={selectedInvoice.client.client_address || selectedInvoice.client.client_email}
                        businessName={selectedInvoice.extra_details.business_name}
                        businessAddress={selectedInvoice.extra_details.business_address}
                        businessLogoUrl={selectedInvoice.extra_details.business_logo}
                        subtotal={selectedInvoice.sub_total}
                        total={selectedInvoice.total}
                        vat={selectedInvoice.vat}
                        items={selectedInvoice.items}
                        onOpenPayModal={onOpenPayModal}
                    />
            )}
            {selectedInvoice && (

            <InvoicePayModal
                isModalOpen={showPayModal}
                currency={'NGN'}
                invoiceId={selectedInvoice.id}
                amount={parseFloat(selectedInvoice.total)}
                onCloseModal={onClosePayModal}
                onOpenStripeModal={onOpenStripeModal}
            />)}

            {selectedInvoice && (
            <StripeModal
                isModalOpen={showStripeModal}
                onCloseModal={onCloseStripeModal}
            />)}
            </Body>
            </Spin>
        </Container>
    )
}

export default Invoices
