import React, { useContext } from "react";
import type { MenuProps } from 'antd';
import {
    Wrapper,
    LeftContent, RightContent, MenuButton, LogoutButton,
    BusinessLogoImage, DropdownLink, DropdownLogoutButton, DropdownContainer
} from "./style";
import { ReactComponent as MenuIcon } from "../../assets/menu2.svg";
import {AuthContext} from "../../../../context/AuthContext";
import {useAppSelector} from "../../../../app/hook";
import logo from "../../../../logo.png";


const Header = () => {
    const { gradientColor, brandColor, businessLogo } = useAppSelector((state) => state.login);
    // const { profile } = useAppSelector((state) => state.login);
    const authContext = useContext(AuthContext);

  const onShowMenu = () => {

  }

    const onLogoutClick = () => {
        authContext.logout();
    };

    const items: MenuProps['items'] = [
        {
            key: 'posts',
            label: (
                <DropdownLink to='/posts'>
                    Posts
                </DropdownLink>
            ),
        },
        {
            key: 'invoices',
            label: (
                <DropdownLink to='/invoices'>
                    Invoices
                </DropdownLink>
            ),
        },
        {
            key: 'accounts',
            label: (
                <DropdownLink to='/connected-accounts'>
                    Connected Accounts
                </DropdownLink>
            ),
        },
        {
            key: 'settings',
            label: (
                <DropdownLink to='/settings'>
                    Settings
                </DropdownLink>
            ),
        },
        {
            key: '4',
            label: (
                <DropdownLogoutButton onClick={onLogoutClick}>
                    Logout
                </DropdownLogoutButton>
            ),
        },
    ];

  return (
    <Wrapper>
      <LeftContent>
          {businessLogo ? <BusinessLogoImage src={businessLogo} alt="ontriv-logo" /> :
              <img src={logo} alt="ontriv-logo" />
          }
      </LeftContent>
      <RightContent>
        {/*<MenuButton onClick={onShowMenu}>*/}
        <MenuButton menu={{items}} overlayClassName="header-logout-dropdown" trigger={['click']}>
            <DropdownContainer>
          <MenuIcon />
            </DropdownContainer>
        </MenuButton>
          <LogoutButton style={{backgroundColor: brandColor || "#2062F4"}} onClick={onLogoutClick}>Logout</LogoutButton>
      </RightContent>
    </Wrapper>
  );
};

export default Header;
