import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {getPosts, updateFacebookPost, updateInstagramPost, updateLinkedinPost, updateTwitterPost} from "./postsAPI";
import {loginUser} from "../../api/authAPI";

const initialState = {
  status: "idle",
  isLoading: false,
  facebookPosts: null,
  linkedinPosts: null,
  instagramPosts: null,
  twitterPosts: null,
};

export const fetchPosts = createAsyncThunk(
  "user/posts",
  async ({ userId }:{userId:number}) => {
    const response = await getPosts({ userId });
    return response;
  }
);

export const modifyTwitterPost = createAsyncThunk(
    "post/twitter",
    async (obj: {
      postId: number;
      updateObj: any;
    }, { rejectWithValue }) => {

      try {
        return await updateTwitterPost(obj);
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
);

export const modifyFacebookPost = createAsyncThunk(
    "post/facebook",
    async (obj: {
      postId: number;
      updateObj: any;
    }, { rejectWithValue }) => {

      try {
        return await updateFacebookPost(obj);
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
);

export const modifyLinkedinPost = createAsyncThunk(
    "post/linkedin",
    async (obj: {
      postId: number;
      updateObj: any;
    }, { rejectWithValue }) => {

      try {
        return await updateLinkedinPost(obj);
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
);

export const modifyInstagramPost = createAsyncThunk(
    "post/instagram",
    async (obj: {
      postId: number;
      updateObj: any;
    }, { rejectWithValue }) => {

      try {
        return await updateInstagramPost(obj);
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
);

export const postSlice = createSlice({
  name: "posts",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.status = "idle";
        const data = action.payload;
        state.facebookPosts = data.user_facebook_post;
        state.linkedinPosts = data.user_linkedin_post;
        state.instagramPosts = data.user_instagram_post;
        state.twitterPosts = data.user_twitter_post;
      })
      .addCase(fetchPosts.rejected, (state) => {
        state.status = "failed";
      })
  },
});

export default postSlice.reducer;
