import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {changeClientLogo, getClientDetails, updateBusinessDetails} from "./dashboardAPI";

const initialState = {
  status: "idle",
  isLoading: false,
  logo: null,
  brandColor: null,
  brandFont: null,
  businessEmail: null,
  businessName: null,
  businessWebsite: null,
  businessCategory: null,
};

export const getClientInfo = createAsyncThunk(
  "user/dashboard",
  async () => {
    const response = await getClientDetails();
    return response;
  }
);

export const updateLogoImage = createAsyncThunk(
    "logo/update",
    async (payload: any, { rejectWithValue }) => {
      try {
        return await changeClientLogo({payload});
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
);

export const updateBusinessAdd = createAsyncThunk(
    "business/update",
    async (payload: any, { rejectWithValue }) => {
      try {
        const response = await updateBusinessDetails(payload);
        return response;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getClientInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getClientInfo.fulfilled, (state, action) => {
        state.status = "idle";
        const data = action.payload;
        state.logo = data.logo;
        state.brandColor = data.brand_color;
        state.brandFont = data.brand_font;
        state.businessEmail = data.client_business_email_address;
        state.businessName = data.client_business_name;
        state.businessWebsite = data.client_business_website;
        state.businessCategory = data.business_category;
      })
      .addCase(getClientInfo.rejected, (state) => {
        state.status = "failed";
      })
        .addCase(updateBusinessAdd.pending, (state) => {
          state.status = "loading";
        })
        .addCase(updateBusinessAdd.fulfilled, (state, action) => {
          state.status = "idle";
          const data = action.payload;
          state.businessEmail = data.client_business_email_address;
          state.businessName = data.client_business_name;
          state.businessWebsite = data.client_business_website;
          state.businessCategory = data.business_category;
          state.logo = data.logo;
        })
        .addCase(updateBusinessAdd.rejected, (state) => {
          state.status = "failed";
        })
        .addCase(updateLogoImage.pending, (state) => {
          state.status = "loading";
        })
        .addCase(updateLogoImage.fulfilled, (state, action) => {
          state.status = "idle";
          const data = action.payload;
          state.logo = data.logo;
        })
        .addCase(updateLogoImage.rejected, (state) => {
          state.status = "failed";
        })
  },
});

export default dashboardSlice.reducer;
