import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import {Spin} from "antd";
import {
    BodyHeader, ClientImage,
    ClientName,
    Container,
    Content, FooterHeader, FooterSide, FooterSubheader,
    Left,
    LeftFooter, LeftFooterWrapper, NoClientImage, NoClientName,
    SocialButton, SocialButtons, BackNav, Nav, NavTitle
} from "./style";
import instagram from "../BusinessAccount/asset/instagram.svg";
import {BsCheckCircleFill} from "react-icons/bs";
import {ReactComponent as AddSocialIcon} from "../BusinessAccount/asset/add-social.svg";
import facebook from "../BusinessAccount/asset/facebook.svg";
import twitter from "../BusinessAccount/asset/twitter.svg";
import linkedin from "../BusinessAccount/asset/linkedin.svg";
import {
    getBusinessConnectionStatus,
    getFacebookCallback,
    getLinkedInCallback,
    getTwitterCallback, clearCallbackUrls
} from "../BusinessAccount/accountSlice";
import {useAppDispatch, useAppSelector} from "../../app/hook";
import {SubmitHandler, useForm} from "react-hook-form";
import {ReactComponent as Back} from "../../components/assets/back.svg";

type Inputs = {
    address: string;
};
const ConnectedAccounts = () => {
    const [openInstagramModal, setOpenInstagramModal] = useState(false);
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<Inputs>();

    const dispatch = useAppDispatch();
    const { user, profile } = useAppSelector((state) => state.login);
    const { businessName, logo } = useAppSelector((state) => state.dashboard);
    const { callbackURL, status, connectionStatus } = useAppSelector((state) => state.account);
    const [isLoading, setIsLoading] = useState(false);


    const onFacebookClick = async () => {
        await dispatch(getFacebookCallback());
    };

    const onLinkedinClick = async () => {
        await dispatch(getLinkedInCallback());
    };

    const onTwitterClick = async () => {
        await dispatch(getTwitterCallback());
    };

    const onInstagramClick = async () => {
        setOpenInstagramModal(true)
    };

    const onCloseInstagramModal = () => {
        setOpenInstagramModal(false);
    };

    const getSocialStatus = async (userId: number) => {
        await dispatch(getBusinessConnectionStatus({id: userId}));
    };


    useEffect(() => {
        if (user && user?.id) {
            getSocialStatus(user?.id);
        }
    }, [user?.id]);

    useEffect(() => {
        if (
            callbackURL?.linkedIn?.length > 0 && status === 'idle'
        ) {
            clearCallbackUrls()
            window.open(callbackURL?.linkedIn, "_blank");
        }
    }, [
        callbackURL?.linkedIn,
    ]);


    useEffect(() => {
        if (
            callbackURL?.facebook?.length > 0 && status === 'idle'
        ) {
            dispatch(clearCallbackUrls())
            window.open(callbackURL?.facebook, "_blank");
        }
    }, [
        callbackURL?.facebook,
    ]);

    useEffect(() => {
        if (
            callbackURL?.twitter?.length > 0 && status === 'idle'
        ) {
            clearCallbackUrls()
            window.open(callbackURL?.twitter, "_blank");
        }
    }, [
        callbackURL?.twitter,
    ]);

    return(
        <>
            <Spin spinning={status === 'loading'}>
        <Container>
            <Left>
                <Nav to='/dashboard'>
                    <BackNav><Back/></BackNav>
                    <NavTitle>Back</NavTitle>
                </Nav>
                {/*{logo || profile?.logo ? <ClientImage src={logo || profile.logo} alt="The logo"/> : <NoClientImage><p>Client Logo</p></NoClientImage>}*/}

                {/*{businessName ?<ClientName>{businessName}</ClientName> : <NoClientName>Client Name</NoClientName>}*/}
                {/*<LeftFooterWrapper>*/}
                {/*    <LeftFooter>*/}
                {/*        <FooterSide>*/}
                {/*            <FooterHeader>Timeline</FooterHeader>*/}
                {/*            <FooterSubheader>2 Months</FooterSubheader>*/}
                {/*        </FooterSide>*/}
                {/*        <FooterSide>*/}
                {/*            <FooterHeader>Date Created</FooterHeader>*/}
                {/*            <FooterSubheader>{dayjs(user.date_joined).format('DD/MM/YYYY')}</FooterSubheader>*/}
                {/*        </FooterSide>*/}
                {/*    </LeftFooter>*/}
                {/*</LeftFooterWrapper>*/}
            </Left>
            <Content>
                <BodyHeader>Connected accounts</BodyHeader>
                <SocialButtons>
                    <SocialButton type='button' onClick={onInstagramClick} disabled={connectionStatus?.instagram}>
                        <div>
                            <img src={instagram} alt="Instagram icon" />
                            <p>Instagram</p>
                        </div>
                        {connectionStatus?.instagram ? (
                            <BsCheckCircleFill color="green" size="18px" className="ms-4" />
                        ) : (
                            <AddSocialIcon/>)}
                    </SocialButton>
                    <SocialButton type='button' onClick={onFacebookClick} disabled={connectionStatus?.facebook}>
                        <div>
                            <img src={facebook} alt="Facebook icon" />
                            <p>Facebook</p>
                        </div>
                        {connectionStatus?.facebook ? (
                            <BsCheckCircleFill color="green" size="18px" className="ms-4" />
                        ) : (
                            <AddSocialIcon/>)}
                    </SocialButton>
                    <SocialButton type='button' onClick={onTwitterClick} disabled={connectionStatus?.twitter_v1}>
                        <div>
                            <img src={twitter} alt="Twitter icon" />
                            <p>Twitter</p>
                        </div>
                        {connectionStatus?.twitter_v1 ? (
                            <BsCheckCircleFill color="green" size="18px" className="ms-4" />
                        ) : (
                            <AddSocialIcon/>)}
                    </SocialButton>
                    <SocialButton type='button' onClick={onLinkedinClick} disabled={connectionStatus?.linkedin}>
                        <div>
                            <img src={linkedin} alt="Linkedin icon" />
                            <p>LinkedIn</p>
                        </div>
                        {connectionStatus?.linkedin ? (
                            <BsCheckCircleFill color="green" size="18px" className="ms-4" />
                        ) : (
                            <AddSocialIcon/>)}
                    </SocialButton>
                </SocialButtons>
            </Content>
        </Container>
            </Spin>
        </>
    )
}

export default ConnectedAccounts
