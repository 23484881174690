import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {Upload} from "antd";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 50px;
  height: 100%;
  background: transparent;
  @media (min-width: 1024px) {
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
  }
  
  & p {
    margin: 0;
  }
`;

export const Left = styled.div`
  display: none;
  @media (min-width: 1024px) {
    display: block;
    max-width: 120px;
  }
`;

export const Nav = styled(NavLink)`
  width: 100%;
  text-decoration: none;

  @media (min-width: 776px) {
    display: flex;
    flex-direction: row;
  }
`;

export const BackNav = styled.div`
  padding: 5px 10px;
  display: none;
  @media (min-width: 1024px) {
    margin-left: 10px;
    display: block;
  }
`;

export const NavTitle = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.35px;
  color: #111317;
  margin-bottom: 24px;
  text-decoration: none;
  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  @media (min-width: 1024px) {
    padding: 40px 55px;
    margin-left: 50px;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const LeftContent = styled.div`
  width: 100%;
  background: #FFFFFF;
  padding: 40px 14px;
  margin-bottom: 30px;
  @media (min-width: 1024px) {
    border: 1px solid #C0CCDA;
    border-radius: 20px;
    padding: 45px;
    width: 48%;
    margin-bottom: unset;
  }
`;

export const RightContent = styled.div`
  width: 100%;
  background: #FFFFFF;
  padding: 40px 14px;
  @media (min-width: 1024px) {
    border-radius: 20px;
    border: 1px solid #C0CCDA;
    padding: 45px;
    width: 48%;
  }
`;

export const Header = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0.35px;
  color: #111317;
  margin-bottom: 40px !important;
  @media (min-width: 1024px) {
    font-size: 20px;
    margin-bottom: 50px !important;
  }
`;

export const FormBox = styled.form`
  display: block;
  //padding: 60px 25px;
  background: #FFFFFF;
  box-shadow: 0px 4px 250px rgba(0, 0, 0, 0.04);
  border-radius: 24px;
  width: 100%;
  & > div {
    margin-bottom: 20px;
  }
`;



// export const UploadButton = styled.button`
//   display: flex;
//   background: #2062F4;
//   border-radius: 10px;
//   align-items: center;
//   justify-content: center;
//   font-weight: 600;
//   font-size: 20px;
//   line-height: 28px;
//   letter-spacing: 0.35px;
//   color: #FFFFFF;
// `;

export const UploadButton = styled(Upload)`
  background: #FFFFFF;
  border: 3px dashed #E5E9F2;
  border-radius: 24px;
  padding: 24px 14px;
  display: flex;
  cursor: pointer;
  & > div > span {
    display: flex;
  }
  
  & div[class~="ant-upload-list"] {
    display: none;
  }

  @media (min-width: 1024px) {
    padding: 24px;
  }
`;
