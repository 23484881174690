import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getBusinessSocialsConnectionStatus,
  getFacebookCallbackURL,
  getLinkedInCallbackURL,
  getTwitterCallbackURL
} from "./accountAPI";

const initialState = {
  status: "idle",
  isLoading: false,
  callbackURL: {
    linkedIn: "",
    facebook: "",
    twitter: "",
    twitterV1: "",
  },
  connectionStatus: {
    instagram: false,
    facebook: false,
    twitter_v1: false,
    twitter_v2: false,
    linkedin: false,
  }
};

export const getBusinessConnectionStatus = createAsyncThunk(
  "social/status",
  async ({id}: {id: number}) => {
    const response = await getBusinessSocialsConnectionStatus({payload: id});
    return response;
  }
);

export const getFacebookCallback = createAsyncThunk(
  "social/facebook",
  async () => {
    const response = await getFacebookCallbackURL();
    return response;
  }
);

export const getLinkedInCallback = createAsyncThunk(
  "social/linkedin",
  async () => {
    const response = await getLinkedInCallbackURL();
    return response;
  }
);

export const getTwitterCallback = createAsyncThunk(
  "social/twitter",
  async () => {
    const response = await getTwitterCallbackURL();
    return response;
  }
);

export const loginSlice = createSlice({
  name: "account",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearCallbackUrls: (state) => {
      state.callbackURL = {
        linkedIn: "",
        facebook: "",
        twitter: "",
        twitterV1: "",
      }
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getBusinessConnectionStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getBusinessConnectionStatus.fulfilled, (state, action) => {
        state.status = "idle";
        state.connectionStatus = action?.payload;
      })
      .addCase(getBusinessConnectionStatus.rejected, (state) => {
        state.status = "failed";
      })

      .addCase(getFacebookCallback.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFacebookCallback.fulfilled, (state, action) => {
        state.status = "idle";
        state.callbackURL.facebook = action?.payload;
      })
      .addCase(getFacebookCallback.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getTwitterCallback.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTwitterCallback.fulfilled, (state, action) => {
        state.status = "idle";
        state.callbackURL.twitter = action?.payload;
      })
      .addCase(getTwitterCallback.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getLinkedInCallback.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getLinkedInCallback.fulfilled, (state, action) => {
        state.status = "idle";
        state.callbackURL.linkedIn = action?.payload;
      })
      .addCase(getLinkedInCallback.rejected, (state) => {
        state.status = "failed";
      })
  },
});

export const { clearCallbackUrls } = loginSlice.actions

export default loginSlice.reducer;
