import React from "react";
import {Spin} from "antd";
import {
    BackNav,
    Container,
    Content, Header,
    Left, LeftContent,
    Nav,
    NavTitle, RightContent, FormBox, UploadButton
} from "./style";
import {ReactComponent as Back} from "../../components/assets/back.svg";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import OntrivInput from "../../components/OntrivInput";
import OntrivButton from "../../components/OntrivButton";
import {useAppDispatch, useAppSelector} from "../../app/hook";
import {
    UploadBlankImage,
    UploadFooter, UploadFooterText,
    UploadHeader,
    UploadImage,
    UploadRight,
    UploadSubheader
} from "../BusinessAccount/style";
import {ReactComponent as AddIcon} from "../BusinessAccount/asset/add.svg";
import {message} from "antd";
import {updateBusinessAdd} from "../Dashboard/dashboardSlice";
import {uploadImage} from "../../utils/upload";

type Inputs = {
    address?: string;
    category: string;
    website?: string;
    name: string;
};

const Settings = () => {
    const {
        logo,
        status,
        businessEmail ,
        businessName,
        businessWebsite,
        businessCategory
    } = useAppSelector((state) => state.dashboard);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<Inputs>({
        defaultValues: {
            address: businessEmail,
            name: businessName,
            website: businessWebsite,
            category: businessCategory,
        }
    });
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useAppDispatch();

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        try {
            const res = await dispatch(updateBusinessAdd({
                client_business_email_address: data.address,
                business_category: data.category,
                client_business_website: data.website,
                client_business_name: data.name,
            })).unwrap();
            messageApi.open({
                type: 'success',
                content: 'Business updated successfully',
            });

        } catch (e) {
            messageApi.open({
                type: 'error',
                content: e.error[0] || 'Business update failed',
            });
        }
    };


    const onUploadError = () => {
        messageApi.open({
            type: 'error',
            content: 'Failed to upload image',
        });
    }

    const onUploadFinish = async (url) => {
        try {
            await dispatch(updateBusinessAdd({logo: url})).unwrap();
            messageApi.open({
                type: 'success',
                content: 'Image updated successfully',
            });
        } catch (err) {
            onUploadError()
        }
    }

    return(
        <>
            {contextHolder}
            <Spin spinning={status === 'loading'}>
        <Container>
            <Left>
                <Nav to='/dashboard'>
                    <BackNav><Back/></BackNav>
                    <NavTitle>Back</NavTitle>
                </Nav>
            </Left>
            <Content>
                <LeftContent>
                    <Header>Business Details</Header>
                    <FormBox onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            name="name"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field }) => (
                                <OntrivInput
                                    label='Business name'
                                    type='text'
                                    field={field}
                                    id="name"
                                />
                            )}
                        />
                        <Controller
                            name="website"
                            control={control}
                            // rules={{
                            //     required: true,
                            // }}
                            render={({ field }) => (
                                <OntrivInput
                                    label='Website'
                                    type='text'
                                    field={field}
                                    id="website"
                                />
                            )}
                        />
                        <Controller
                            name="address"
                            control={control}
                            // rules={{
                            //     required: true,
                            // }}
                            render={({ field }) => (
                                <OntrivInput
                                    label='Office address'
                                    type='text'
                                    field={field}
                                    id="address"
                                />
                            )}
                        />
                        <Controller
                            name="category"
                            control={control}
                            // rules={{
                            //     required: true,
                            // }}
                            render={({ field }) => (
                                <OntrivInput
                                    label='Business category'
                                    type='text'
                                    field={field}
                                    id="category"
                                />
                            )}
                        />
                        <OntrivButton
                            type="submit"
                            label="Update"
                        />
                    </FormBox>
                </LeftContent>
                <RightContent>
                    <Header>Business logo</Header>
                    <UploadButton
                        // accept="image/*"
                        // customRequest={({ file }) => handlePictureUpload(file)}
                        name="doc1"
                        accept="image/*"
                        customRequest={({ file }) => uploadImage({file, onFinish: onUploadFinish, onUploadError})}
                    >
                        {/*{...props} onChange={handlePictureUpload}>*/}
                        {logo ? <UploadImage src={logo} alt="logo" /> : <UploadBlankImage/>}
                        {/*{logo ? <UploadImage src={logo} alt="logo" style={{ width: '100%' }} /> : <UploadBlankImage/>}*/}
                        <UploadRight>
                            <UploadHeader>Upload Logo Here</UploadHeader>
                            <UploadSubheader><span>Image format with</span> max size of 3MB</UploadSubheader>
                            <UploadFooter>
                                <AddIcon/>
                                <UploadFooterText>Upload <span>new</span> photo</UploadFooterText>
                            </UploadFooter>
                        </UploadRight>
                    </UploadButton>
                </RightContent>
            </Content>
        </Container>
            </Spin>
            </>
    )
}

export default Settings
