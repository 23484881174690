import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDFetg3q3MCr0lfzODQfmJ4ydEKKblTldQ",
  authDomain: "ontriv123.firebaseapp.com",
  projectId: "ontriv123",
  storageBucket: "ontriv123.appspot.com",
  messagingSenderId: "517622118377",
  appId: "1:517622118377:web:ab299fccc9e6f23645bd78"
};

const firestoreApp = initializeApp(firebaseConfig);

export default firestoreApp
