import api from "../../api/api";

export async function getClientDetails() {
    const { data } = await api.get(`/business/api/v1/manage-client/`);
    // const { data } = await api.get(`/client/api/v1/client/profile/`);
    return data;
}

export async function changeClientLogo({ payload } : any) {
    const { data } = await api.patch(
        // `/client/api/v1/client/profile/`,
        `/business/api/v1/manage-client/`,
        payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    return data;
}

export async function updateBusinessDetails(payload: any) {
    // const { data } = await api.patch(`/client/api/v1/client/profile/`, payload);
    const { data } = await api.patch(`/business/api/v1/manage-client/`, payload);
    return data;
}
