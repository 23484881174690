import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {getInvoices, getInvoiceTotal, getPaymentKeys, updateInvoiceToPaid} from "./invoiceAPI";


export type Key = {
    id : number;
    name : "flutterwave" | "stripe" | "paystack",
    public_key : string,
    secret_key : string,
    status : boolean,
    user : string
}

const initialState = {
    status: "idle",
    isLoading: false,
    invoices: null,
    totalPending: null,
    totalPaid: null,
    keys:[]
};

export const fetchInvoice = createAsyncThunk(
    "invoice/all",
    async () => {
        const response = await getInvoices();
        return response;
    }
);

export const fetchInvoiceTotal = createAsyncThunk(
    "invoice/total",
    async () => {
        const response = await getInvoiceTotal();
        return response;
    }
);

export const updateInvoiceStatus = createAsyncThunk(
    "invoice/update",
    async ({id}: {id: number}, { rejectWithValue }) => {
        try {
            const response = await updateInvoiceToPaid({id});
            return response;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const fetchPaymentKeys = createAsyncThunk(
    "payment/keys",
    async () => {
        const response = await getPaymentKeys();
        return response;
    }
);

export const invoiceSlice = createSlice({
    name: "invoice",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(fetchInvoice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchInvoice.fulfilled, (state, action) => {
                state.status = "idle";
                const data = action.payload;
                state.invoices = data;
            })
            .addCase(fetchInvoice.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(updateInvoiceStatus.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateInvoiceStatus.fulfilled, (state, action) => {
                state.status = "idle";
                const data = action.payload;

                const invoiceExists = state.invoices.findIndex(
                    (payM) => payM?.id === data?.id
                );

                if (invoiceExists === -1) {
                    state.invoices.unshift(data);
                } else {
                    state.invoices.splice(invoiceExists, 1, data);
                }
            })
            .addCase(updateInvoiceStatus.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(fetchInvoiceTotal.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchInvoiceTotal.fulfilled, (state, action) => {
                state.status = "idle";
                const data = action.payload;
                state.totalPaid = data.total_paid;
                state.totalPending = data.total_pending;
            })
            .addCase(fetchInvoiceTotal.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(fetchPaymentKeys.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchPaymentKeys.fulfilled, (state, action) => {
                state.status = "idle";
                const data = action.payload;
                state.keys = data;
            })
            .addCase(fetchPaymentKeys.rejected, (state) => {
                state.status = "failed";
            })
    },
});

export default invoiceSlice.reducer;
