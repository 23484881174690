import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Body, Wrapper } from "./style";
import Header from "./components/Header";
// import { SuccessAlertContext } from "../../context/SuccessAlertContext";
// import SuccessAlert from "../common/alerts/SuccessAlert";
// import { NetworkErrorAlertContext } from "../../context/NetworkErrorAlert";
// import NetworkErrorAlert from "../common/alerts/NetworkErrorAlert";
// import Loader from "../common/Loader";
// import { useAppSelector } from "../../app/hooks";

const AppShell = () => {
  // const { showAlert, alertMessage } = useContext(SuccessAlertContext);
  // const { showAlert: showErrorAlert, alertMessage: errorAlertMessage } =
  //   useContext(NetworkErrorAlertContext);
  // const { status: userStatus } = useAppSelector((state) => state.users);



  // if (userStatus === "loading") {
  //   return <Loader isLoading isNotFull />;
  // }
  return (
    <Wrapper>
        {/*{showErrorAlert && <NetworkErrorAlert message={errorAlertMessage} />}*/}
        {/*{showAlert && <SuccessAlert message={alertMessage} />}*/}

        <Header />
        <Body>
          <Outlet />
        </Body>
    </Wrapper>
  );
};

export default AppShell;
