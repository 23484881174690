import React, { useMemo, useState } from "react";
import { Wrapper, Body, Tabs, Tab } from "./style";
import { ReactComponent as CloseIcon } from "../assets/close-circle.svg";
import CardForm from "../stripeForms/CardForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import IbanForm from "../stripeForms/IbanForm";
import { useAppSelector } from "../../app/hook";

interface StripeModalProps {
  isModalOpen: boolean;
  onCloseModal: () => void;
}
const StripeModal = ({ isModalOpen, onCloseModal }: StripeModalProps) => {
  const [selectedMethod, setSelectedMethod] = useState("card");

  const { keys } = useAppSelector((state) => state.invoice);

  const stripeKey = useMemo(() => {
    let stripeObject: string;
    if (keys?.length) {
      stripeObject = keys?.find(
        (key: { name: string }) => key?.name === "stripe"
      )?.public_key;
    }
    return stripeObject;
  }, [keys]);
  // const stripeKey =
  //   "pk_test_51Ml86jCE1a90CsNaT2mTi3aGNvhRLrkj7M4nohKSGYyoAIiEqBVwdDzc2eaPMAyny7hYSO3XkExroeIHyxdQtL5k004Ojqhutg";
  const stripePromise = loadStripe(stripeKey);

  const onClickTab = (method) => {
    setSelectedMethod(method);
  };

  return (
    <Wrapper
      title=""
      open={isModalOpen}
      footer={null}
      width={"min(80%, 780px)"}
      style={{ borderRadius: 30 }}
      maskStyle={{ backgroundColor: "rgba(29, 30, 44, 0.62)" }}
      closeIcon={<CloseIcon />}
      onCancel={onCloseModal}
    >
      <Tabs>
        <Tab
          id="card"
          $selected={selectedMethod === "card"}
          onClick={() => onClickTab("card")}
        >
          <p>Card</p>
        </Tab>
        {/*<Tab id='alipay' $selected={selectedMethod === 'alipay'} onClick={() => onClickTab('alipay')}><p>AliPay</p></Tab>*/}
        {/*<Tab id='us_bank_account' $selected={selectedMethod === 'us_bank_account'} onClick={() => onClickTab('us_bank_account')}><p>US account</p></Tab>*/}
        {/*<Tab id='iban' $selected={selectedMethod === 'iban'} onClick={() => onClickTab('iban')}><p>US account</p></Tab>*/}
      </Tabs>

      <Body>
        <Elements stripe={stripePromise}>
          {selectedMethod === "card" ? <CardForm /> : null}
          {selectedMethod === "iban" ? <IbanForm /> : null}
        </Elements>
      </Body>
    </Wrapper>
  );
};

export default StripeModal;
