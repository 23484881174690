import styled  from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #F9FAFC;
`;

export const Top = styled.div`
  width: 100%;
  margin-bottom: 50px;
  padding: 40px 60px;
  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
  align-items: center;
`;

export const BodyHeaderText = styled.p`
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  color: #032041;
  margin-bottom: 25px;
  text-align: center;
  @media (min-width: 1024px) {
    display: block;
  }
`;

export const BodyHeaderMessage = styled.p`
  display: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #17151E;
  margin-bottom: 50px;
  text-align: center;
  @media (min-width: 1024px) {
    display: block;
  }
`;

export const Content = styled.form`
  display: block;
  width: min(90%, 500px);
`;

export const FormBox = styled.form`
  background: #FFFFFF;
  box-shadow: -20px 4px 30px rgba(0, 0, 0, 0.03);
  border-radius: 24px;
  display: block;
  padding: 50px 60px;
  & > div {
    margin-bottom: 20px;
  }
  width: 100%;
  @media (min-width: 776px) {
    
  }
`;

export const AlreadyHaveAcc = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #EFF2F7;
  border-radius: 24px;
  margin: 25px 0;
  width: 100%;
  padding: 20px 80px;
  //height: 66px;
  
  & > p {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
  }
  
  & > a {
    text-decoration: none;
    //color: #7337FD;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    background: linear-gradient(164.82deg, #2062F4 3.9%, #7337FD 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
`;

export const SentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  //padding: 5%;
`

export const SentHeader = styled.p`
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  color: #032041;
  margin-bottom: 30px;
  text-align: center;
  @media (min-width: 1024px) {
    font-size: 34px;
    line-height: 41px;
  }
`

export const SentDescription = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #17151E;
  text-align: center;
  @media (min-width: 1024px) {
    font-size: 14px;
    line-height: 22px;
  }
`
