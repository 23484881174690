import styled, {css} from "styled-components";
import { NavLink } from "react-router-dom";

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 96px);
  margin-top: -30px;
  & div[class~="ant-spin-nested-loading"],& div[class~="ant-spin-container"] {
    height: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  margin-bottom: 30px;
  @media (min-width: 1024px) {
    background-color: #ffffff;
    margin-right: 24px;
    border-radius: 10px;
    width: 300px;
    height: 280px;
  }
`;

export const ClientImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 300px;
`

export const NoClientImage = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 300px;
  background: linear-gradient(180deg, #6AD5ED 0%, #49A8F8 100%);
`

export const ClientName = styled.p`
  margin-top: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #231F20;
`

export const Content = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 4px 250px rgba(0, 0, 0, 0.04);
  //border-radius: 10px;
  padding: 40px 15px;
  height: 100%;

  @media (min-width: 1024px) {
    //padding: 45px 64px;
    padding: 45px 10%;
    flex: 1;
  }
`;

// export const MenuCard = styled.div`
export const MenuCard = styled(NavLink)`
  background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  margin-bottom: 12px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 1024px) {
    padding: 30px 64px;
  }
`;

export const MenuLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const MenuLogo = styled.div`
  & path {
    ${(props: {
      $color?: string;
    }) => {
      if (props.$color) {
        return css`
          fill: ${(props: { $color?: string }) => props.$color};
        `;
      }
    }}
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 15px;
  @media (min-width: 1024px) {
    margin-right: 32px;
  }
`;

export const MenuName = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #04004D;
`;

export const MenuNav = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.04px;
  color: #0B6BFF;
  text-decoration: none;
  ${(props: {
    $color?: string;
  }) => {
    if (props.$color) {
      return css`
        color: ${(props: { $color?: string }) => props.$color || '#0B6BFF'};
        `;
    }
  }}
`;
