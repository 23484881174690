import React from "react";
import {Amount, Container, Currency, Title, Value} from "./style";

const InvoiceCard = ({title, amount = '0.00'}: {title: string, amount: string}) => {

    return(
        <Container>
            <Title>{title}</Title>
            <Amount>
                <Currency>&#8358;</Currency>
                <Value>{amount}</Value>
            </Amount>
        </Container>
    )
}

export default InvoiceCard
