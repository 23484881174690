import React, { useEffect, useState } from "react";
import {message, Spin} from 'antd';
import {
    Container,
    Content,
    LeftContent,
    RightContent,
    FormBox,
    UploadButton,
    LeftContentHeaderDiv,
    LeftContentHeader,
    RightContentLabel,
    UploadImage,
    UploadBlankImage,
    UploadRight,
    UploadHeader,
    UploadSubheader,
    UploadFooter,
    UploadFooterText,
    SocialButtons,
    SocialButton,
    SubmitButtonContainer
} from "./style";
import {ReactComponent as AccountLogo} from "./asset/logo.svg";
import {ReactComponent as AddIcon} from "./asset/add.svg";
import {ReactComponent as AddSocialIcon} from "./asset/add-social.svg";

// import {ReactComponent as InstagramIcon} from "./asset/instagram.svg";
// import {ReactComponent as LinkedinIcon} from "./asset/linkedin.svg";
// import {ReactComponent as FacebookIcon} from "./asset/facebook.svg";
// import {ReactComponent as TwitterIcon} from "./asset/twitter.svg";

import instagram from "./asset/instagram.svg";
import linkedin from "./asset/linkedin.svg";
import facebook from "./asset/facebook.svg";
import twitter from "./asset/twitter.svg";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import OntrivInput from "../../components/OntrivInput";
import OntrivButton from "../../components/OntrivButton";
import {useAppDispatch, useAppSelector} from "../../app/hook";
import {
    getBusinessConnectionStatus,
    getFacebookCallback,
    getLinkedInCallback,
    getTwitterCallback
} from "./accountSlice";
import {BsCheckCircleFill} from "react-icons/bs";
import {getClientInfo, updateBusinessAdd, updateLogoImage} from "../Dashboard/dashboardSlice";
import {uploadImage} from "../../utils/upload";


type Inputs = {
    address: string;
};

const BusinessAccount = () => {
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<Inputs>();
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.login);
    const { callbackURL, status, connectionStatus } = useAppSelector((state) => state.account);
    const {
        logo,
        status: dashboardStatus,
    } = useAppSelector((state) => state.dashboard);
    const [openInstagramModal, setOpenInstagramModal] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();


    const getBusinessData = async () => {
        await dispatch(getClientInfo());
    };

    useEffect(() => {
        if (user && user?.id) {
            getBusinessData();
        }
    }, [user]);

    const onFacebookClick = async () => {
        await dispatch(getFacebookCallback());
    };

    const onLinkedinClick = async () => {
        await dispatch(getLinkedInCallback());
    };

    const onTwitterClick = async () => {
        await dispatch(getTwitterCallback());
    };

    const onInstagramClick = async () => {
        setOpenInstagramModal(true)
    };

    const onCloseInstagramModal = () => {
        setOpenInstagramModal(false);
    };

    const getSocialStatus = async (userId: number) => {
        await dispatch(getBusinessConnectionStatus({id: userId}));
    };


    useEffect(() => {
        if (user && user?.id) {
            getSocialStatus(user?.id);
        }
    }, [user?.id]);

    useEffect(() => {
        if (
            callbackURL?.linkedIn?.length > 0 && status === 'idle'
        ) {
            window.open(callbackURL?.linkedIn, "_blank");
        }
    }, [
        callbackURL?.linkedIn,
    ]);


    useEffect(() => {
        if (
            callbackURL?.facebook?.length > 0 && status === 'idle'
        ) {
            window.open(callbackURL?.facebook, "_blank");
        }
    }, [
        callbackURL?.facebook,
    ]);

    useEffect(() => {
        if (
            callbackURL?.twitter?.length > 0 && status === 'idle'
        ) {
            window.open(callbackURL?.twitter, "_blank");
        }
    }, [
        callbackURL?.twitter,
    ]);

    const onSubmit: SubmitHandler<Inputs> = async (data) => {

        try {
            const payload = {
                address: data?.address,
            };
            const res = await dispatch(updateBusinessAdd(payload)).unwrap();
            messageApi.open({
                type: 'success',
                content: 'Business updated successfully',
            });
        } catch (e) {
            messageApi.open({
                type: 'error',
                content: e.error[0] || 'Business update failed',
            });
        }
    };


    const onUploadError = () => {
        messageApi.open({
            type: 'error',
            content: 'Failed to upload image',
        });
    }

    const onUploadFinish = async (url) => {
        try {
            await dispatch(updateBusinessAdd({logo: url})).unwrap();
            messageApi.open({
                type: 'success',
                content: 'Image updated successfully',
            });
        } catch (err) {
            onUploadError()
        }
    }



    return(
        <Container>
            {contextHolder}
            <Spin spinning={status === 'loading' || dashboardStatus === 'loading'}/>
            <Content>
                <LeftContent>
                    {/*<Header>Business Details</Header>*/}
                    <LeftContentHeaderDiv>
                        <AccountLogo/>
                        <LeftContentHeader>Set up business
                            Information</LeftContentHeader>
                    </LeftContentHeaderDiv>
                    <UploadButton
                        name="doc1"
                        accept="image/*"
                        customRequest={({ file }) => uploadImage({file, onFinish: onUploadFinish, onUploadError})}
                    >
                        {logo ? <UploadImage src={logo} alt="avatar" /> : <UploadBlankImage/>}
                        <UploadRight>
                            <UploadHeader>Upload Logo Here</UploadHeader>
                            <UploadSubheader><span>Image format with</span> max size of 3MB</UploadSubheader>
                            <UploadFooter>
                                <AddIcon/>
                                <UploadFooterText>Upload <span>new</span> photo</UploadFooterText>
                            </UploadFooter>
                        </UploadRight>
                    </UploadButton>
                </LeftContent>
                <RightContent>
                    {/*<Header>Business logo</Header>*/}
                    <FormBox onSubmit={handleSubmit(onSubmit)}>
                        <RightContentLabel>Enter Business address</RightContentLabel>
                        <Controller
                            name="address"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field }) => (
                                <OntrivInput
                                    label='Business address'
                                    type='text'
                                    field={field}
                                    id="address"
                                />
                            )}
                        />
                        <RightContentLabel style={{marginTop: 35}}>Add social media accounts</RightContentLabel>
                        <SocialButtons>
                            <SocialButton type='button' onClick={onInstagramClick} disabled={connectionStatus?.instagram}>
                                <div>
                                    <img src={instagram} alt="Instagram icon" />
                                    {/*<InstagramIcon/>*/}
                                    <p>Instagram</p>
                                </div>
                                {connectionStatus?.instagram ? (
                                    <BsCheckCircleFill color="green" size="18px" className="ms-4" />
                                ) : (
                                <AddSocialIcon/>)}
                            </SocialButton>
                            <SocialButton type='button' onClick={onFacebookClick} disabled={connectionStatus?.facebook}>
                                <div>
                                    <img src={facebook} alt="Facebook icon" />
                                    {/*<FacebookIcon/>*/}
                                    <p>Facebook</p>
                                </div>
                                {connectionStatus?.facebook ? (
                                    <BsCheckCircleFill color="green" size="18px" className="ms-4" />
                                ) : (
                                    <AddSocialIcon/>)}
                            </SocialButton>
                            <SocialButton type='button' onClick={onTwitterClick} disabled={connectionStatus?.twitter_v1}>
                                <div>
                                    <img src={twitter} alt="Twitter icon" />
                                    {/*<TwitterIcon/>*/}
                                    <p>Twitter</p>
                                </div>
                                {connectionStatus?.twitter_v1 ? (
                                    <BsCheckCircleFill color="green" size="18px" className="ms-4" />
                                ) : (
                                    <AddSocialIcon/>)}
                            </SocialButton>
                            <SocialButton type='button' onClick={onLinkedinClick} disabled={connectionStatus?.linkedin}>
                                <div>
                                    <img src={linkedin} alt="Linkedin icon" />
                                    {/*<LinkedinIcon/>*/}
                                    <p>LinkedIn</p>
                                </div>
                                {connectionStatus?.linkedin ? (
                                    <BsCheckCircleFill color="green" size="18px" className="ms-4" />
                                ) : (
                                    <AddSocialIcon/>)}
                            </SocialButton>
                        </SocialButtons>
                        <SubmitButtonContainer>
                            <OntrivButton
                                width="320px"
                                type="submit"
                                label="Done"
                            />
                        </SubmitButtonContainer>
                    </FormBox>
                </RightContent>
            </Content>
        </Container>
    )
}

export default BusinessAccount
