import styled from "styled-components";
import { Dropdown } from 'antd';
import {NavLink} from "react-router-dom";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  padding: 16px;
  width: 100%;
  @media (min-width: 1024px) {
    padding: 20px 50px;
  }
`;

export const LeftContent = styled.div`
    & > img {
      height: 50px;
      width: unset;
    }
`;

export const BusinessLogoImage = styled.img`    
  height: 50px;
  //max-height: 50px;
  object-fit: contain;
`;

export const RightContent = styled.div`
  display: flex;
  align-items: center;
`;

export const MenuButton = styled(Dropdown)`
  border: none;
  background-color: transparent;
  display: flex;
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const DropdownContainer = styled.div`
  padding: 10px;
`

export const DropdownLink = styled(NavLink)`
  width: 100%;
  height: 30px;
  display: flex;
`;

export const DropdownLogoutButton = styled.button`
  width: 100%;
  height: 30px;
  background-color: white;
  border: none;
  text-align: left;
  padding-left: 0;
`

export const LogoutButton = styled.button`
  border: none;
  display: none;
  color: #ffffff;
  background-color: #2062F4;
  border-radius: 10px;
  width: 140px;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  cursor: pointer;
  font-family: 'Poppins';
  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const ClientImage = styled.img`
  height: 50px;
  min-width: 100px;
`

export const NoClientImage = styled.div`
  height: 50px;
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #6AD5ED 0%, #49A8F8 100%);
`
