import styled from "styled-components";
import { Checkbox } from 'antd';

export const LoadingContainer = styled.form`
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.form`
  //display: flex;
  width: 100vw;
  height: 100vh;
`;
export const Content = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

export const Left = styled.div`
  display: none;
  width: min(40%, 590px);
  max-width: min(40%, 590px);
  background-image: linear-gradient(180deg, rgba(73, 168, 248, 1), rgba(0, 83, 244, 1));
  height: 100%;
  padding: 0 5%;
  & > p {
    color: #FFFFFF;
  }
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const WelcomeText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 50px;
  margin-bottom: 29px;
  //max-width: 290px;
`;

export const WelcomeMessage = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  //max-width: 400px;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  padding: 100px 0 40px;
  width: 100%;
  background: #F9FAFC;
  max-height: 100%;
  overflow-y: scroll;
  align-items: center;
  @media (min-width: 768px) {
    width: 95%;
  }
`;

export const LogoBox = styled.div`
  margin-bottom: 45px;
  display: flex;
  justify-content: center;
  & > img {
    max-height: 100px;
    width: unset;
  }
  @media (min-width: 768px) {
  }
`;

export const BusinessLogoImage = styled.img`    
  max-height: 100px;
  object-fit: contain;
`;

export const FormBox = styled.div`
  display: block;
  padding: 60px 25px;
  background: #FFFFFF;
  box-shadow: 0px 4px 250px rgba(0, 0, 0, 0.04);
  border-radius: 24px;
  & > div {
    margin-bottom: 20px;
  }
  @media (min-width: 768px) {
    width: min(85%, 500px);
  }
`;

export const RememberMe = styled(Checkbox)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: #52575C;
`;

export const RememberForgot = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  
  & > a {
    color: #2DCA73;
    text-decoration: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
`;
