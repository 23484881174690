import React, {useEffect} from "react";
import {Spin} from "antd";
import {
    ClientImage,
    ClientName,
    Container,
    Content,
    Left,
    MenuCard,
    MenuLeft,
    MenuLogo,
    MenuName,
    MenuNav, NoClientImage, Wrapper
} from "./style";
import {ReactComponent as AllPosts} from "./assets/all-posts.svg";
import {ReactComponent as Invoices} from "./assets/invoices.svg";
import {ReactComponent as ConnectedAccounts} from "./assets/calendar.svg";
import {ReactComponent as Documents} from "./assets/document.svg";
import {ReactComponent as Settings} from "./assets/setting.svg";
import {useAppDispatch, useAppSelector} from "../../app/hook";
import {getClientInfo} from "./dashboardSlice";


const Dashboard = () => {
    const dispatch = useAppDispatch();
    const { profile, user, brandColor} = useAppSelector((state) => state.login);
    const { status, logo, businessName } = useAppSelector((state) => state.dashboard);

    const getBusinessData = async () => {
        await dispatch(getClientInfo());
    };

    useEffect(() => {
        if (user && user?.id) {
            getBusinessData();
        }
    }, [user]);

    return(
        <Wrapper>
            <Spin spinning={status === 'loading'}>
        <Container>
            {/*<Left>*/}
            {/*    {logo ? <ClientImage src={logo} alt="The logo"/> : <NoClientImage/>}*/}
            {/*    <ClientName>{businessName}</ClientName>*/}
            {/*</Left>*/}
            <Content>
                <MenuCard to='/posts'>
                    <MenuLeft>
                        <MenuLogo $color={brandColor}><AllPosts/></MenuLogo>
                        <MenuName>All Posts</MenuName>
                    </MenuLeft>
                    <MenuNav $color={brandColor}>View</MenuNav>
                </MenuCard>
                <MenuCard to='/invoices'>
                    <MenuLeft>
                        <MenuLogo $color={brandColor}><Invoices/></MenuLogo>
                        <MenuName>Invoices</MenuName>
                    </MenuLeft>
                    <MenuNav $color={brandColor}>View</MenuNav>
                </MenuCard>
                <MenuCard to='/connected-accounts'>
                    <MenuLeft>
                        <MenuLogo $color={brandColor}><ConnectedAccounts/></MenuLogo>
                        <MenuName>Connected Accounts</MenuName>
                    </MenuLeft>
                    <MenuNav $color={brandColor}>View</MenuNav>
                </MenuCard>
                {/*<MenuCard>*/}
                {/*    <MenuLeft>*/}
                {/*        <MenuLogo><Documents/></MenuLogo>*/}
                {/*        <MenuName>Documents</MenuName>*/}
                {/*    </MenuLeft>*/}
                {/*    <MenuNav to='/documents'>View</MenuNav>*/}
                {/*</MenuCard>*/}
                <MenuCard to='/settings'>
                    <MenuLeft>
                        <MenuLogo $color={brandColor}><Settings/></MenuLogo>
                        <MenuName>Settings</MenuName>
                    </MenuLeft>
                    <MenuNav $color={brandColor}>View</MenuNav>
                </MenuCard>
            </Content>
        </Container>
            </Spin>
        </Wrapper>
    )
}

export default Dashboard
