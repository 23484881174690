import React, { useEffect, useState } from "react";
import { usePaystackPayment } from "react-paystack";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { EmptyKey, Header, PayButton, Wrapper } from "./style";
import { ReactComponent as CloseIcon } from "../assets/close-circle.svg";
import paystack from "./assets/paystack.png";
import flutterwave from "./assets/flutterwave.png";
import stripe from "./assets/stripe.png";
import { useAppDispatch, useAppSelector } from "../../app/hook";
import { Key, updateInvoiceStatus } from "../../pages/Invoices/invoiceSlice";
import { message } from "antd";

export type InvoiceItem = {
  amount: string;
  date_created: string;
  date_modified: string;
  item_description: string;
  rate: string;
  id: number;
  invoice: number;
  quantity: number;
};

interface InvoicePayModalProps {
  isModalOpen: boolean;
  currency: string;
  amount: number;
  invoiceId: number;
  onCloseModal: () => void;
  onOpenStripeModal: () => void;
}

type KeyObjectType = {
  flutterwave?: Key;
  paystack?: Key;
  stripe?: Key;
};

const InvoicePayModal = ({
  invoiceId,
  onCloseModal,
  isModalOpen,
  onOpenStripeModal,
  amount,
  currency = "NGN",
}: InvoicePayModalProps) => {
  const dispatch = useAppDispatch();
  const { keys } = useAppSelector((state) => state.invoice);
  const { user } = useAppSelector((state) => state.login);
  const [keysObject, setKeysObject] = useState<KeyObjectType>({});
  const [activeKeys, setActiveKeys] = useState([]);

  useEffect(() => {
    const keyObj = {};
    if (keys) {
      const keysActive = keys.filter(key => key.status)
      setActiveKeys(keysActive)

      keysActive.forEach((key) => {
        keyObj[key.name] = key;
      });
      setKeysObject(keyObj);
    }
  }, [keys]);

  const updateInvoiceStatToPaid = async () => {
    try {
      await dispatch(updateInvoiceStatus({ id: invoiceId })).unwrap();
      message.success("Invoice status updated");
    } catch (e) {
      message.error("Invoice status update failed");
    }
  };
  const flutterWaveConfig = {
    public_key: keysObject?.flutterwave?.public_key,
    // public_key: 'FLWPUBK_TEST-3352308ebf86eaeb11413b303dd2e9dd-X',
    tx_ref: Date.now(),
    amount: +amount,
    currency,
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: user?.email,
      phone_number: user?.phone,
      name: user?.fullname,
    },
  };
  // @ts-ignore
  const handleFlutterPayment = useFlutterwave(flutterWaveConfig);

  // you can call this function anything
  const onSuccess = (reference) => {
    console.log(reference);
    // TODO: store refrence
    updateInvoiceStatToPaid();
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // console.log('closed')
    onCloseModal();
  };

  const paystackConfig = {
    reference: new Date().getTime().toString(),
    email: user?.email,
    amount: +amount * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: keysObject?.paystack?.public_key,
  };
  const initializePaystackPayment = usePaystackPayment(paystackConfig);

  return (
    <Wrapper
      title=""
      open={isModalOpen}
      footer={null}
      width={"min(80%, 525px)"}
      // style={{borderRadius: 30, padding: '35px 65px'}}
      style={{ borderRadius: 30 }}
      maskStyle={{ backgroundColor: "rgba(29, 30, 44, 0.62)" }}
      closeIcon={<CloseIcon />}
      onCancel={onCloseModal}
    >
      {/*<Content>*/}
      <Header>Select Payment Provider</Header>

      {activeKeys?.length === 0 ? <EmptyKey>Payment not configured</EmptyKey> : null}

      {keysObject?.flutterwave?.status ? (
        <PayButton
          type="button"
          key="flutterwave"
          onClick={() => {
            handleFlutterPayment({
              callback: (response) => {
                if (response.status === "successful") {
                  onSuccess(response.flw_ref);
                }
                closePaymentModal(); // this will close the modal programmatically
                onCloseModal();
              },
              onClose: () => {
                onCloseModal();
              },
            });
          }}
        >
          <img src={flutterwave} style={{ height: 50 }} alt="flutterwave" />
        </PayButton>
      ) : null}
      {keysObject?.paystack?.status ? (
        <PayButton
          type="button"
          key="paystack"
          onClick={() => {
            // @ts-ignore
            initializePaystackPayment(onSuccess, onClose);
          }}
        >
          <img src={paystack} style={{ height: 30 }} alt="paystack" />
        </PayButton>
      ) : null}
      {keysObject?.stripe?.status ? (
        <PayButton
          type="button"
          key="stripe"
          onClick={() => {
            onOpenStripeModal();
          }}
        >
          <img src={stripe} style={{ height: 50 }} alt="stripe" />
        </PayButton>
      ) : null}
    </Wrapper>
  );
};

export default InvoicePayModal;
