import api from "../../api/api";

export async function getPosts({ userId }:{userId:number}) {
  const { data } = await api.get(`client/api/v1/user/${userId}/posts/`);
  // const { data } = await api.get(`/twitter/api/v1/tweet/`);
  return data;
}

export async function updateTwitterPost({postId, updateObj}: {postId:number, updateObj: any}) {
  const { data } = await api.patch(`/twitter/api/v1/tweet/${postId}`, updateObj);
  return data;
}

export async function updateFacebookPost({postId, updateObj}: {postId:number, updateObj: any}) {
  const { data } = await api.patch(`/instagram/api/v1/facebook/post/6/${postId}`, updateObj);
  return data;
}

export async function updateLinkedinPost({postId, updateObj}: {postId:number, updateObj: any}) {
  const { data } = await api.patch(`/linkedin/api/v1/post/${postId}`, updateObj);
  return data;
}

export async function updateInstagramPost({postId, updateObj}: {postId:number, updateObj: any}) {
  const { data } = await api.patch(`/instagram/api/v1/instagram/post/${postId}`, updateObj);
  return data;
}

// export async function getWirePaymentsFiltered(payload) {
//   const { data } = await api.post(
//     "/merchant/bulkmerchantpayment/admin/paginated/filter",
//     payload
//   );
//   return data?.bulkMerchantPayments;
// }
