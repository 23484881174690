import React, { useState } from "react";

import { ButtonWrapper, InputContainer, Wrapper } from "./style";
import { ReactComponent as VisiblePassword } from "./assets/eye.svg";
import { ReactComponent as InvisiblePassword } from "./assets/not-visible-eye.svg";

interface InputProps {
    label: string;
    defaultValue?: string;
    type?: "text" | "number" | "password" | "email";
    field?: any;
    onClick?: any;
    readonly?: boolean;
    disabled?: boolean;
    onChange?: any;
    placeholder?: string;
    classname?: any;
    hasError?: boolean;
    height?: string;
    id?: string;
}
const PasswordIcon = (
    {isVisible, onPasswordToggle}
        :
    { isVisible: boolean; onPasswordToggle: () => void; }
) => {
    return (
        <ButtonWrapper type="button" onClick={onPasswordToggle}>
            {isVisible ? <VisiblePassword /> : <InvisiblePassword />}
        </ButtonWrapper>
    );
};

export const OntrivInput = (
    {
        label,
        type = "text",
        field,
        onClick,
        onChange,
        readonly = false,
        defaultValue,
        classname,
        hasError = false,
        disabled = false,
        height,
        id = "",
    }: InputProps
) => {
    const [passWordIsVisible, setPassWordIsVisible] = useState(false);
    const [hasFocus, setHasFocus] = useState(false);
    const [passwordTypeState, setPasswordTypeState] = useState("password");
    const onPasswordToggle = () => {
        setPassWordIsVisible(!passWordIsVisible);
        setPasswordTypeState(!passWordIsVisible ? "text" : "password");
    };

    return (
        <Wrapper
            $disabled={disabled}
            $height={height}
            className={`${classname || ""} ${hasFocus ? "has-focus" : ""} ${
                hasError ? "error" : ""
            }`}
            onFocus={() => {
                setHasFocus(true);
            }}
            onBlur={() => {
                setHasFocus(false);
            }}
        >
            <InputContainer>
                <input
                    id={id}
                    placeholder={label}
                    defaultValue={defaultValue}
                    readOnly={readonly}
                    disabled={disabled}
                    type={type === "password" ? passwordTypeState : type}
                    {...field}
                    onClick={onClick && onClick}
                    value={
                        id.includes("email")
                            ? field?.value?.toLowerCase() || ""
                            : field?.value || ""
                    }
                    onChange={(e) => {
                        field.onChange(e);
                        onChange && onChange(e);
                    }}
                />
                {type === "password" && field.value?.trim() && (
                    <PasswordIcon
                        isVisible={passWordIsVisible}
                        onPasswordToggle={onPasswordToggle}
                    />
                )}
            </InputContainer>
        </Wrapper>
    );
};

export default OntrivInput
