import styled, {css} from "styled-components";
import {Modal} from "antd";

export const Wrapper = styled(Modal)`
  width: min(90%, 780px);
  //padding: 0 16px;
`;

export const PostStatus = styled.div`
  background: #E5ECF2;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  max-width: 135px;
  justify-content: center;
  border-radius: 5px;
  & > div {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    margin-right: 5px;
    //margin: 0;
  }
  & > p {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-transform: capitalize;
    //margin-bottom: 0;
    margin: 0;
  }
  ${(props: {
    $status: "published" | "pending" | "scheduled";
}) => {
    if (props.$status === "published") {
        return css`
        & > div {
          background-color: #57D9A3;
        }
        & > p {
          color: #57D9A3;
        }
      `;
    } else if (props.$status === "pending") {
        return css`
        & > div {
          background-color: #FF2154;
        }
        & > p {
          color: #FF2154;
        }
      `;
    } else if (props.$status === "scheduled") {
        return css`
        & > div {
          background-color: #2062F4;
        }
        & > p {
          color: #2062F4;
        }
      `;
    }
}}
`;

export const Body = styled.div`
    display: flex;
`;

export const BodyContent = styled.div`
  flex: 1;
`;

export const ImageContainer = styled.div`
  width: 60%;
  margin-right: 20px;
`;

export const ImageBox = styled.img`
  width: 100%;
  height: 220px;
  object-fit: cover;
  @media (min-width: 760px) {
    height: 417px;
  }
`;

export const TextHeader = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.25px;
  background: linear-gradient(93.88deg, #49A8F8 6.88%, #0053F4 74.45%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  opacity: 0.8;
  margin-bottom: 25px;
`;

export const TextContentContainer = styled.p`
  height: 130px;
  overflow-y: auto;
  @media (min-width: 760px) {
    height: 330px;
  }
`

export const TextContent = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: #111317;
`;


export const PostFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`

export const PostButton = styled.button`
  background: #2062F4;
  border-radius: 10px;
  height: 40px;
  width: 170px;
  border: none;
  color: #ffffff;
  cursor: pointer;
`;

export const EditText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.04px;
  color: #0B6BFF;
  margin-top: 8px;
`;
