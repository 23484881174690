import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import {
    Container,
    FormBox,
    Body,
    AlreadyHaveAcc,
    BodyHeaderText,
    Top, Content, BodyHeaderMessage, SentContainer, SentHeader, SentDescription,
} from "./style";
import OntrivInput from "../../components/OntrivInput";
import OntrivButton from "../../components/OntrivButton";

type Inputs = {
    email: string;
};

const ResetSent = () => {
    return(
        <SentContainer>
            <SentHeader>
                We've sent a link to reset your password
            </SentHeader>
            <SentDescription>
                Check your inbox to log in. If you still have trouble, contact us.
            </SentDescription>
        </SentContainer>
    )
}

const ResetPassword = () => {
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<Inputs>();
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);
    // const { onShowAlert } = useContext(NetworkErrorAlertContext);

    const mailformat = /^\w+([._+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;


    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        setIsLoading(true);


        setSubmitted(true);
        setIsLoading(false);
    };

    if(isSubmitted) {
        return <ResetSent/>
    }
    return (
        <Container>
            {/*<Top>fff</Top>*/}
            <Body>
                <Content>
                    <BodyHeaderText>Reset password</BodyHeaderText>
                    <BodyHeaderMessage>
                        Enter your registered email address, and we'll<br/> send you a link to reset your password
                    </BodyHeaderMessage>
                    <FormBox onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            name="email"
                            control={control}
                            rules={{
                                required: true,
                                pattern: mailformat,
                            }}
                            render={({ field }) => (
                                <OntrivInput
                                    label='Email address'
                                    type='email'
                                    field={field}
                                    id="email"
                                />
                            )}
                        />
                        <OntrivButton
                            type="submit"
                            label="Reset Password"
                        />
                    </FormBox>
                    <AlreadyHaveAcc>
                        <p>Remember Password?</p>
                        <NavLink to="/login">Log In</NavLink>
                    </AlreadyHaveAcc>
                </Content>
            </Body>
        </Container>
    )

};

export default ResetPassword
