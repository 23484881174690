import styled, {css} from "styled-components";
import {NavLink} from "react-router-dom";
import {Table, Tabs} from 'antd';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #FFFFFF;
  height: 100%;
  padding: 12px;
  @media (min-width: 1024px) {
    padding: 24px 80px;
  }
`;

export const Nav = styled.div`
  width: 100%;
  margin-bottom: 24px;
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
  }
`;

export const BackNav = styled(NavLink)`
  padding: 5px 10px;
  display: none;
  @media (min-width: 1024px) {
    margin-left: 10px;
    display: block;
  }
`;

export const NavTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.35px;
  color: #111317;
  margin-bottom: 24px;
  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const Content = styled.div`
  
  @media (min-width: 1024px) {
    padding-left: 30px;
  }
`;

export const CustomTab = styled(Tabs)<{$brandColor?: string}>`
  & div[class~="ant-tabs-tab-btn"] {
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    @media (min-width: 1024px) {
        font-size: 14px;
    }
  }
  
  & div[class~="ant-tabs-tab-active"] div[class~="ant-tabs-tab-btn"] {
    color: ${(props) => props.$brandColor || "#2062F4"} !important;
  }

  & div[class~="ant-tabs-nav-list"] > div[class~="ant-tabs-ink-bar"] {
    background-color: ${(props) => props.$brandColor || "#2062F4"} !important;
  }
  
  & div[class~="ant-tabs-nav"]::before {
    border-bottom: 1px solid #E5ECF2; 
  }
   //ant-tabs-content-top
  & div[class~="ant-tabs-content-top"] {
    overflow-y: scroll;
    height: 400px;
  }
`

export const PostColumn = styled.div`
  display: flex;
  max-width: 600px;
`

export const PostColumnImageContainer = styled.div`
  background: #E5ECF2;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin-right: 14px;
`

export const PostColumnImage = styled.img`
  width: 80px;
  aspect-ratio: 1.4/1;
  border-radius: 5px;
  object-fit: cover;
  @media (min-width: 760px) {
    width: 130px;
  }
`
export const PostColumnVideo = styled.video`
  width: 80px;
  aspect-ratio: 1.4/1;
  border-radius: 5px;
  object-fit: cover;
  @media (min-width: 760px) {
    width: 130px;
  }
`


export const PostColumnUser = styled.div`
  display: none;
  background: #F9FAFC;
  border-radius: 20px;
  height: 30px;
  padding: 5px 20px 5px 5px;
  
  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    max-width: 200px;
  }
`

export const PostColumnUserImage = styled.img`
  border: 1px solid #49A8F8;
  height: 22px;
  width: 22px;
  margin-right: 10px;
  border-radius: 22px;
`

export const PostColumnUserName = styled.p`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.25px;
  color: #111317;
`

export const PostColumnText = styled.p`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.25px;
  color: #111317;
  margin-bottom: 7px;
  text-align: left;
`

export const TableText = styled.p`
  text-transform: capitalize;
  text-align: left;
  font-size: 12px;
  line-height: 14px;
  font-family: 'Poppins';

`

export const PostStatus = styled.div`

  display: flex;
  align-items: center;
  //justify-content: center;
  & > div {
    width: 7px;
    height: 7px;
    border-radius: 7px;
    margin-right: 10px;
  }
  & > p {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-transform: capitalize;
  }
  ${(props: {
    $status: "published" | "pending" | "scheduled";
  }) => {
    if (props.$status === "published") {
      return css`
        & > div {
          background-color: #57D9A3;
        }
        & > p {
          color: #57D9A3;
        }
      `;
    } else if (props.$status === "pending") {
      return css`
        & > div {
          background-color: #FF2154;
        }
        & > p {
          color: #FF2154;
        }
      `;
    } else if (props.$status === "scheduled") {
      return css`
        & > div {
          background-color: #2062F4;
        }
        & > p {
          color: #2062F4;
        }
      `;
    }
  }}
`;


export const PostsTable = styled(Table)`
  margin-top: 32px;
  padding-bottom: 30px;
  

  &
    div[class~="ant-table"]
    div[class~="ant-table-content"]
    thead[class="ant-table-thead"] th {
    background-color: #F9FAFC;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.04px;
    color: #111317;
    border-bottom: none;
  }

  & tbody[class="ant-table-tbody"] > tr > td {
    border-bottom: 0.5px solid #E5ECF2;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.04px;
    color: #111317;
  }
`;

export const EmptyTabContent = styled.div`
  width: 100%;
  padding: 120px 0;
  
  & > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.35px;
    color: #111317;
    text-align: center;
  }
`
