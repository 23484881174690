import api from "../../api/api";

type Payload = {
    code?: string;
    payload?: string;
    state?: string;
}

export async function getBusinessSocialsConnectionStatus({payload}: { payload: number }) {
    const { data } = await api.get(`/client/api/v1/user/${payload}/social/accounts/`);
    return data;
}

export async function getLinkedInCallbackURL() {
    const { data } = await api.get(`/linkedin/api/v1/auth/`);
    return data;
}

export async function getFacebookCallbackURL() {
    const { data } = await api.get(`/instagram/api/v1/auth/`);
    return data;
}

export async function sendLinkedInCodeState({payload}: { payload: Payload }) {
    const { data } = await api.get(`/linkedin/api/v1/auth/linkedin/callback/?code=${payload?.payload}&state=pyd%24%29x%5Ebq%24tk5bfq%25m_p5%28z%29-%3Dir0rb8kkq%25%212p%3Dlxkk*t4%29_j`);
    return data;
}

export async function sendFacebookCodeState({payload}: { payload: Payload }) {
    const { data } = await api.get(`/instagram/api/v1/auth/facebook/callback/?code=${payload?.code}&state=${payload?.state}`
    );
    return data;
}

export async function getConnectedInstagramAccount() {
    const { data } = await api.get(`/instagram/api/v1/auth/instagram/login/`);
    return data;
}

export async function connectInstagramAccount() {
    const { data } = await api.get(`/instagram/api/v1/auth/instagram/login/`);
    return data;
}

export async function getTwitterCallbackURL() {
    const { data } = await api.get(`/twitter/api/v1/auth/v2`);
    return data;
}

export async function getTwitterV1CallbackURL() {
    const { data } = await api.get(`/twitter/api/v1/auth/v1/`);
    return data;
}

export async function sendTwitterCodeState({payload}: { payload: Payload }) {
    const { data } = await api.get(`/twitter/api/v1/auth/twitter/callback/v2/?code=${payload?.code}&state=${payload?.state}`);
    return data;
}

export async function sendTwitterV1CodeState({payload}: { payload: Payload }) {
    const { data } = await api.get(`/twitter/api/v1/auth/twitter/callback/v1/?code=${payload?.code}&state=${payload?.state}`);
    return data;
}

// export async function changeClientLogo(payload : any) {

// export async function getInvoices() {
//     const { data } = await api.get(``);
//     return data;
// }
//
// export async function getInvoices() {
//     const { data } = await api.get(``);
//     return data;
// }
