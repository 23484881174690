import React, { useEffect, useState } from "react";
import type { TabsProps } from 'antd';
import dayjs from 'dayjs';
import {Spin} from "antd";
import {
    BackNav,
    Container,
    Content,
    CustomTab, EmptyTabContent,
    Nav,
    NavTitle,
    PostColumn,
    PostColumnImage,
    PostColumnImageContainer,
    PostColumnText,
    PostColumnUser, PostColumnUserImage, PostColumnUserName, PostColumnVideo,
    PostsTable,
    PostStatus,
    TableText
} from "./style";
import {ReactComponent as Back} from "../../components/assets/back.svg";
import {useAppDispatch, useAppSelector} from "../../app/hook";
import {fetchPosts} from "./postSlice";
import PostModal from "../../components/PostModal";
import {Post} from "../../components/PostModal/types";
import {truncateText} from "../../utils/helper";

const columns = [
    {
        title: 'Post',
        dataIndex: 'name',
        key: 'name',
        render: (_: any, record: any) => (
            <PostColumn>
                <PostColumnImageContainer>
                    {record?.media_type?.toLowerCase() === "image" ? (
                        <PostColumnImage src={record?.post_details?.media} alt="" />
                    ) : (
                        <PostColumnVideo src={record?.post_details?.media} />
                    )}
                </PostColumnImageContainer>
                <div>
                    <PostColumnText
                        dangerouslySetInnerHTML={{
                        __html: JSON.stringify(
                            truncateText(record?.post_details?.caption, 170)
                        )
                            ?.replace(/\\n/g, "<br>")
                            ?.replaceAll('"', ""),
                    }}/>
                    <PostColumnUser>
                        <PostColumnUserImage src={record.post_details?.media}/>
                        <PostColumnUserName>{record.post_details?.user}</PostColumnUserName>
                    </PostColumnUser>
                </div>
            </PostColumn>
        )
    },
    {
        title: 'Post Status',
        dataIndex: 'post_status',
        key: 'status',
        render: (text: "published" | "pending" | "scheduled") => (
            <PostStatus $status={text}>
                <div/>
                <p>{text}</p>
            </PostStatus>
        ),
    },
    {
        title: 'Date',
        dataIndex: 'created_at',
        key: 'date',
        render: (text: string) => (<TableText>{dayjs(text).format('DD/MM/YYYY HH:mm A')}</TableText>)
    },
    {
        title: 'Post Type',
        dataIndex: 'media_type',
        key: 'type',
        render: (text: string) => (<TableText>{text ? text.toLowerCase() : '--'}</TableText>)
    },
    ]

type SocialType = "facebook" | "instagram" | "linkedin" | "twitter"

const Posts = () => {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.login);
    const { facebookPosts, linkedinPosts, instagramPosts, twitterPosts, status } = useAppSelector((state) => state.post);
    const {  brandColor } = useAppSelector((state) => state.login);
    const [postData, setPostData] = useState<Post[] | null>(instagramPosts);
    const [selectedSocial, setSelectedSocial] = useState<SocialType>('instagram');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPost, setSelectedPost] = useState<Post | null>(null);
    const [selectedStatus, setSelectedStatus] = useState('all');
    const [selectedDateRange, setSelectedDateRange] = useState([]);

    const getAllPosts = async (userId: number) => {
        await dispatch(fetchPosts({userId}));
    };

    useEffect(() => {
        if (user && user?.unique_code) {
            getAllPosts(user?.unique_code);
        }
    }, [user]);

    useEffect(() => {
        if(instagramPosts && selectedSocial === 'instagram')
        setPostData(instagramPosts)
    }, [instagramPosts]);

    const onSelectPost = (post: any) => {
        setSelectedPost(post)
        setIsModalOpen(true)
    }

    const onCloseModal = () => {
        setIsModalOpen(false)
    }

    useEffect(() => {
        setSelectedStatus('all')
        setSelectedDateRange([])
        if(selectedSocial === 'instagram') {
            setPostData(instagramPosts)
        } else if(selectedSocial === 'facebook') {
            setPostData(facebookPosts)
        } else if(selectedSocial === 'linkedin') {
            setPostData(linkedinPosts)
        } else if(selectedSocial === 'twitter') {
            setPostData(twitterPosts)
        }
    }, [selectedSocial]);

    useEffect(() => {
        let filPosts = postData;
        if(selectedStatus !== 'all' && filPosts !== null) {
            filPosts = filPosts.filter((po:Post) => po.post_status === selectedStatus);
        }
        setPostData(filPosts)
    }, [selectedStatus, selectedDateRange]);

    const onTabChange = (key: SocialType) => {
        setSelectedSocial(key);
    };

    const items: TabsProps['items'] = [
        {
            key: 'instagram',
            label: `Instagram`,
            children: postData ? (
                <PostsTable
                    dataSource={postData}
                    columns={columns}
                    pagination={false}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => {onSelectPost(record)}
                        };
                    }}
                />
            ) : (
                <EmptyTabContent>
                    <p>No available posts at the moment,<br/> please check back later</p>
                </EmptyTabContent>
            )
        },
        {
            key: 'facebook',
            label: `Facebook`,
            children: postData ? (
                <PostsTable
                    dataSource={postData}
                    columns={columns}
                    pagination={false}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => {onSelectPost(record)}
                        };
                    }}
                />
            ) : (
                <EmptyTabContent>
                    <p>No available posts at the moment,<br/> please check back later</p>
                </EmptyTabContent>
            )
        },
        {
            key: 'linkedin',
            label: `LinkedIn`,
            children: postData ? (
                <PostsTable
                    dataSource={postData}
                    columns={columns}
                    pagination={false}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => {onSelectPost(record)}
                        };
                    }}
                />
            ) : (
                <EmptyTabContent>
                    <p>No available posts at the moment,<br/> please check back later</p>
                </EmptyTabContent>
            )
        },
        {
            key: 'twitter',
            label: `Twitter`,
            children: postData ? (
                <PostsTable
                    dataSource={postData}
                    columns={columns}
                    pagination={false}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => {onSelectPost(record)}
                        };
                    }}
                />
            ) : (
                <EmptyTabContent>
                    <p>No available posts at the moment,<br/> please check back later</p>
                </EmptyTabContent>
            )
        },
    ];

    return (
        <Container>
            <Spin spinning={status === 'loading'}>
            <Nav>
                <BackNav to='/dashboard'><Back/></BackNav>
                <NavTitle>Posts</NavTitle>
            </Nav>
            <Content>
                <CustomTab
                    $brandColor={brandColor}
                    defaultActiveKey="1"
                    items={items}
                    onChange={onTabChange}
                />
            </Content>
            {
                selectedPost&&<PostModal
                    isModalOpen={isModalOpen}
                    status={selectedPost.post_status}
                    postId={selectedPost.id}
                    caption={selectedPost?.post_details?.caption}
                    imageUrl={selectedPost?.post_details?.media}
                    onCloseModal={onCloseModal}
                    postType={selectedSocial}
                />
            }
            </Spin>
        </Container>
    )
}

export default Posts
