import React, { useContext, useState } from "react";
import {
  ImageBox,
  PostStatus,
  Wrapper,
  Body, TextHeader, TextContent, PostButton, EditText, PostFooter, BodyContent, ImageContainer, TextContentContainer
} from "./style";
import {ReactComponent as CloseIcon} from "../assets/close-circle.svg";
import {
  fetchPosts,
  modifyFacebookPost,
  modifyInstagramPost,
  modifyLinkedinPost,
  modifyTwitterPost
} from "../../pages/Posts/postSlice";
import {useAppDispatch, useAppSelector} from "../../app/hook";
import {message} from "antd";

interface PostModalProps {
  isModalOpen: boolean;
  status: "published" | "pending" | "scheduled"
  postType: "facebook" | "instagram" | "linkedin" | "twitter"
  postId: number;
  caption: string;
  imageUrl: string;
  onCloseModal: () => void
}
const PostModal = ({
                     isModalOpen,
    status,
                     imageUrl,
                     postId,
                     caption,
                     onCloseModal,
                     postType
}: PostModalProps) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.login);

  const updateTwitterObj = async (postId, updateObj) => {
    await dispatch(modifyTwitterPost({postId, updateObj})).unwrap();
  };

  const updateFacebookObj = async (postId, updateObj) => {
    await dispatch(modifyFacebookPost({postId, updateObj})).unwrap();
  };

  const updateLinkedinObj = async (postId, updateObj) => {
    await dispatch(modifyLinkedinPost({postId, updateObj})).unwrap();
  };

  const updateInstagramObj = async (postId, updateObj) => {
    await dispatch(modifyInstagramPost({postId, updateObj})).unwrap();
  };

const onApprove = async () => {
  try {
    if(postType === 'twitter') {
    await updateTwitterObj(postId, {post_status: 'scheduled'})
  }
  else if(postType === 'instagram') {
    await updateInstagramObj(postId, {post_status: 'scheduled'})
  }
  else if(postType === 'facebook') {
    await updateFacebookObj(postId, {post_status: 'scheduled'})
  }
  else if(postType === 'linkedin') {
    await updateLinkedinObj(postId, {post_status: 'scheduled'})
  }
  onCloseModal()
    message.success('Post approved')
  await dispatch(fetchPosts({userId: user?.unique_code}));
  } catch (e) {
    message.error('Post approval failed')
  }
}


  return (
    <Wrapper
        title=""
        open={isModalOpen}
        footer={null} width={'min(80%, 780px)'}
        style={{borderRadius: 30}}
        maskStyle={{backgroundColor: 'rgba(29, 30, 44, 0.62)'}}
        closeIcon={<CloseIcon/>}
        onCancel={onCloseModal}
    >
      <PostStatus $status={status}>
        <div/>
        <p>{status}</p>
      </PostStatus>
      <Body>
        <ImageContainer><ImageBox src={imageUrl}/></ImageContainer>
        <BodyContent>
          <TextHeader>Caption</TextHeader>
          <TextContentContainer><TextContent>{caption}</TextContent></TextContentContainer>
          {
            status === 'pending' ? (
            // status === 'scheduled' ? (
                <PostFooter>
                  <PostButton type='button' onClick={onApprove}>
                    Approve
                  </PostButton>
                  {/*<EditText>*/}
                  {/*  Edit*/}
                  {/*</EditText>*/}
                </PostFooter>
            ) : null
          }
        </BodyContent>
      </Body>
    </Wrapper>
  );
};

export default PostModal;
