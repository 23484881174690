// A mock function to mimic making an async request for data
import axios from "axios";

export async function loginUser(credentials: {
  email: string;
  password: string;
}) {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_URL || "https://ontriv.spinxe.com/"}accounts/api/v1/login/`,
    credentials
  );
  return data;
}

export async function businessColors(credentials: {
  uniqueId: string;
}) {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL || "https://ontriv.spinxe.com/"}business/api/v1/business/color/${credentials.uniqueId}`,
  );
  return data;
}

// Client/api/v1/register/
//
//
// Emmanuel for socials, invoices, posts,
//
//     Settings in business details - client/api/v1/client/profile
//
// Posts - http://ontriv.herokuapp.com/client/api/v1/user/44/posts/
// Invoice - no endpoint yet
// Document - no endpoint yet
// Social media - http://ontriv.herokuapp.com/linkedin/api/v1/auth/


export async function signupUser(payload: {
  email: string;
  // username: string;
  password: string;
}) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL || "https://ontriv.spinxe.com/"}client/api/v1/register/`,
    payload
  );
  return response;
}

export async function confirmRegistration({ id }: { id?: string }) {
  if (id) {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}auth/user/confirmregistration/${id}`
    );
    return response;
  }
}

export async function forgotPassword({
  email,
  domain,
}: {
  email: string;
  domain: string;
}) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}auth/user/resetPassword/${email}`,
    { email, domain }
  );
  return response;
}

export async function changePassword({
  password,
  token,
}: {
  password: string;
  token: string;
}) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}auth/user/savePassword`,
    { oldPassword: password, newPassword: password, token }
  );
  return response;
}

export async function resendActivation({
  email,
  url,
}: {
  email: string;
  url: string;
}) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}auth/user/resendToken`,
    { email, url }
  );
  return response;
}
