import React from "react";
import { ButtonWrapper } from "./style";
import {useAppSelector} from "../../app/hook";

interface InputProps {
  label: string;
  theme?: "primary" | "secondary" | "alternate";
  type?: "submit" | "button";
  width?: string;
  fontSize?: string;
  height?: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const OntrivButton = ({
  label,
  type = "button",
  theme = "primary",
  width = "100%",
  fontSize = "16px",
  height = "56px",
  onClick,
  disabled = false,
}: InputProps) => {
  const { gradientColor, brandColor } = useAppSelector((state) => state.login);

  return (
    <ButtonWrapper
      $theme={theme}
      $width={width}
      type={type}
      onClick={onClick}
      $fontSize={fontSize}
      $height={height}
      disabled={disabled}
      $backgroundColor={brandColor}
    >
      {label}
    </ButtonWrapper>
  );
};

export default OntrivButton
