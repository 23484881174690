import styled, {css} from "styled-components";
import {NavLink} from "react-router-dom";
import {Table} from "antd";

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 50px;
  background: #FFFFFF;
  height: 100%;
  @media (min-width: 1024px) {
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
  }
`;

export const Left = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 1024px) {
    width: 390px;
  }
`;

export const Nav = styled.div`
  width: 100%;
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
  }
`;

export const BackNav = styled(NavLink)`
  padding: 5px 10px;
  display: none;
  @media (min-width: 1024px) {
    margin-left: 10px;
    display: block;
  }
`;

export const NavTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.35px;
  color: #111317;
  margin-bottom: 24px;
  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const Cards = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    & > div {
      margin-bottom: 22px;
    }
  }
`;

export const HistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 1024px) {
    padding: 35px 48px;
    border-left: 1px solid #E0E0E0;
  }
`;

export const HistoryTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #111317;
  margin-bottom: 21px;
  @media (max-width: 768px) {
    margin-bottom: 28px;
  }
`;

export const HistoryBody = styled.div`
  border-top: 1px solid #E0E0E0;
  
  @media (max-width: 768px) {
  }
`;

export const HistoryTable = styled(Table)`
  margin-top: 32px;

  & div[class~="ant-table"] div[class~="ant-table-content"] {
    overflow-x: auto;
    overflow-y: hidden;
  }
  //&
  //  div[class~="ant-table"]
  //  div[class~="ant-table-content"]
  //  thead[class="ant-table-thead"] {
  //  //background: #f5f5f5;
  //  border-radius: 8px;
  //}

  & tbody[class="ant-table-tbody"] > tr > td {
    border-bottom: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.04px;
    color: #9DA8B6;
  }

  .ant-table-tbody > tr > td {
    padding: 8px 0px;
    padding-right: 24px;
  }

  & thead[class="ant-table-thead"] > tr > th {
    background-color: #ffffff;
    border-bottom: unset;
    font-style: normal;
    font-weight: 500;
    padding: 8px 0;
    padding-right: 24px;
    white-space: nowrap;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */

    letter-spacing: 0.04px;

    color: #04004D;

    :not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none;
    }
  }

  .ant-table-tbody > tr > td:first-child,
  .ant-table-tbody > tr > td:last-child {
    padding: 8px 16px;
  }

  & thead[class="ant-table-thead"] > tr > th:first-child,
  & thead[class="ant-table-thead"] > tr > th:last-child {
    padding: 8px 16px;
  }

  & thead[class="ant-table-thead"] > tr > th:first-child {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }

  & thead[class="ant-table-thead"] > tr > th:last-child {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }

  &
    tbody[class="ant-table-tbody"]
    > tr
    > td[class~="ant-table-cell"]:last-child
    > div {
    justify-content: flex-end;
  }

  //& tbody[class="ant-table-tbody"] > tr:nth-of-type(even) > td {
  //  background-color: #fafafa;
  //}

  & li[class~="ant-pagination-options"] div[class="ant-select-selector"] {
    border-radius: 8px !important;
    border: 1px solid #cccccc !important;
    border-color: #cccccc !important;
  }

  & li[class~="ant-pagination-prev"],
  & li[class~="ant-pagination-next"] {
    border-radius: 8px !important;
    width: 32px;
    & button[class="ant-pagination-item-link"] {
      border: 1px solid rgba(0, 0, 0, 0.7);
      border-color: rgba(0, 0, 0, 0.7);
      border-radius: 8px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & button[class="ant-pagination-item-link"]:disabled {
      border: 1px solid #cccccc;
      border-color: #cccccc;
      border: none;
    }
  }

  & li[class~="ant-pagination-item"] {
    width: 32px;
    border-radius: 8px !important;
    border: 1px solid #cccccc !important;
    border-color: #cccccc !important;
    display: flex;
    align-items: center;
    justify-content: center;
    & > a {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      color: rgba(0, 0, 0, 0.7);
    }
  }

  & li[class~="ant-pagination-item-active"] {
    border: 1px solid #ef2c5a !important;
    border-color: #ef2c5a !important;
    & > a {
      color: #ef2c5a;
    }
  }
`;

export const TableText = styled.p`
  text-transform: capitalize;
  text-align: left;
`

export const InvoiceStatus = styled.div`

  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  padding: 8px 20px;
  border-radius: 10px;
  height: 34px;
  width: 130px;

  & > div {
    width: 7px;
    height: 7px;
    border-radius: 7px;
    margin-right: 10px;
  }
  & > p {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-transform: capitalize;
  }
  ${(props: {
    $status: "Overdue" | "Pending" | "Paid";
}) => {
    if (props.$status === "Overdue") {
        return css`
          background-color: rgba(255, 33, 84, 0.15);
        & > div {
          background-color:  #FF2154;
        }
        & > p {
          color: #FF2154;
        }
      `;
    } else if (props.$status === "Pending") {
        return css`
          background-color: rgba(237, 128, 0, 0.15);
          & > div {
            background-color: #ED8000;
          }
            & > p {
              color: #ED8000;
            }
      `;
    } else if (props.$status === "Paid") {
        return css`
          background-color: rgba(87, 217, 163, 0.15);
        & > div {
          background-color: #57D9A3;
        }
        & > p {
          color: #57D9A3;
        }
      `;
    }
}}
`;
