import styled, {css} from "styled-components";
import {Modal} from "antd";

export const Wrapper = styled(Modal)`
  width: min(80%, 780px);
  //padding: 0 16px;
`;

export const Tabs = styled.div`
display: flex;
align-items: center;
  margin-bottom: 20px;
`

export const Tab = styled.button`
margin-right: 10px;
padding: 5px;
border-radius: 5px;
  cursor: pointer;
  border: 0.5px solid #e6e6e6;
  background-color: #ffffff;
  color: #6d6e78;
  ${(props: {
    $selected: boolean;
  }) => {
    if (props.$selected) {
      return css`
        color: #0570de;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 1px rgb(5, 112, 222);
      `;
    } else {
        
    }
  }}
`
export const Body = styled.div``
