import React, { createContext, ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import { persistor } from "../../app/store";
import api from "../../api/api";

type AuthState = {
  token: string | null;
};
interface AuthContextInterface {
  authState: AuthState;
  setAuthState: any;
  logout: any;
}

const AuthContext = createContext<AuthContextInterface | null>(null);
const { Provider } = AuthContext;

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();

  const userToken = localStorage.getItem("token") || null;

  const [authState, setAuthState] = useState({
    token: userToken,
  });

  const setAuthInfo = (token: string) => {
    localStorage.setItem("token", token);

    setAuthState({
      token,
    });
  };

  api.interceptors.response.use(undefined, (error) => {
    if (
      error.response.status === 401 ||
      error.response.data.message === "401 Unauthorized"
    ) {
      logout();
    }
    return Promise.reject(error);
  });

  const logout = () => {

    const userUniqueId=localStorage.getItem('business_code')
    persistor
      .purge()
      .then(() => {
        persistor.flush().then();
        localStorage.removeItem("token");
        localStorage.removeItem("u-1-a");
        localStorage.removeItem("expiresAt");
      })
      .then(() => navigate(`/login?code=${userUniqueId || ''}`));
  };

  return (
    <Provider
      value={{
        authState,
        setAuthState: (authInfo: string) => setAuthInfo(authInfo),
        logout,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext };
export default AuthProvider;
