import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {businessColors, loginUser} from "../../api/authAPI";
import {LoginState} from "./types";

const initialState : LoginState = {
  status: "idle",
  isLoading: false,
  accessToken: null,
  profile: null,
  refreshToken: null,
  user: null,
  gradientColor: null,
  brandColor: null,
  businessLogo: null
};

export const signinUser = createAsyncThunk(
  "user/login",
  async (credentials: {
    email: string;
    password: string;
  }, { rejectWithValue }) => {

    try {
      return await loginUser(credentials);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getBusinessColors = createAsyncThunk(
  "business/colors",
  async (credentials: {
    uniqueId: string;
  }, { rejectWithValue }) => {

    try {
      return await businessColors(credentials);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addBusinessColors: (state, action) => {
      // state.uniqueId = action.payload
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(signinUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(signinUser.fulfilled, (state, action) => {
        state.status = "idle";
        const data = action.payload;
        state.accessToken = data.access_token;
        state.refreshToken = data.refresh_token;
        state.user = data.user;
        state.profile = data.profile;
      })
      .addCase(signinUser.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getBusinessColors.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getBusinessColors.fulfilled, (state, action) => {
        state.status = "idle";
        const data = action.payload;
        state.gradientColor = data.gradient_color;
        state.brandColor = data.brand_color;
        state.businessLogo = data.logo;
      })
      .addCase(getBusinessColors.rejected, (state) => {
        state.status = "failed";
      })
  },
});

export const { addBusinessColors } = loginSlice.actions;

export default loginSlice.reducer;
