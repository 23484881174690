import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import firestoreApp from "../lib/firebase";

export const uploadImage = ({
  file,
  uploadPath,
  onFinish,
                              onUploadError
}: {
  file: any;
  uploadPath?: string;
  onFinish?: (docUrl: any) => void;
  onUploadError: () => void;
}) => {
  const imageId = file.uid;
  const storage = getStorage(firestoreApp);

  const splitFileName = file.name.split(".");
  const docFileType = splitFileName[splitFileName.length - 1];
  const storageRef = ref(
    storage,  uploadPath || `/profile-pictures/${file.name}`
  );
  const uploadTask = uploadBytesResumable(storageRef, file);
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      // const progressToTwoDec = Number(progress.toFixed(2));
    },
    (error) => {
      onUploadError()
    },
    () => {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      getDownloadURL(uploadTask.snapshot.ref)
        .then((downloadURL) => {
          onFinish(downloadURL);
        })
    }
  );
};
