import api from "../../api/api";

export async function getInvoices() {
    const { data } = await api.get(`/invoice/api/v1/invoice/client/get/all/`);
    return data;
}


export async function updateInvoiceToPaid({id}: {id:number}) {
    const { data } = await api.patch(`/invoice/api/v1/invoice/full/${id}`, {status: 'Paid'});
    // const { data } = await api.patch(`/invoice/api/v1/invoice/full/${id}`, {status: 'Pending'});
    return data;
}

export async function getInvoiceTotal() {
    const { data } = await api.get(`/invoice/api/v1/client/invoice/get/totals`);
    return data;
}


export async function getPaymentKeys() {
    const { data } = await api.get(`/payment/api/v1/payment-gateway/`);
    return data;
}
