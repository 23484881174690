import styled from "styled-components";

export const Wrapper = styled.div`
  overflow: hidden;
  margin: 0; /* removes default style */
  background-color: #F9FAFC;
  padding: 14px 20px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const Body = styled.div`
  flex: 1 1;
  position: relative;
  margin: 20px 0;
  @media (min-width: 1024px) {
    margin: 30px 0;
  }
`;
