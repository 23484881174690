import styled from "styled-components";
import { Upload } from 'antd';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 50px;
  height: 100%;
  background: transparent;
  @media (min-width: 1024px) {
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
  }
  
  //& p {
  //  margin: 0;
  //}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  //padding: 40px 55px;
  border-radius: 24px;
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const LeftContent = styled.div`
  width: 100%;
  background: #FFFFFF;
  //padding: 100px 120px;
  padding: 40px 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  //border-radius: 20px;
  @media (max-width: 1023px) {
    width: 100%;
  }
  @media (min-width: 1024px) {
    width: 48%;
    border-right: 1px solid #E0E6ED;
    //padding: 50px 120px;
    padding: 50px 20px;
    border-right: 1px solid #E0E6ED;
  }
  @media (min-width: 1400px) {
    //padding: 50px 120px;
    padding: 50px 80px;
  }
`;

export const LeftContentHeaderDiv = styled.div`
  margin-bottom: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`

export const LeftContentHeader = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 38px;
  //display: flex;
  //align-items: center;
  letter-spacing: 0.374px;
  text-align: center;

  color: #111317;
  margin-top: 24px;
  @media (min-width: 1024px) {
    font-size: 34px;
    line-height: 41px;
    margin-top: 0;
    margin-left: 40px !important;
    text-align: left;
  }
`

export const RightContentLabel = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.35px;
  color: #17151E;
  margin-bottom: 25px;
  opacity: 0.8;
`

export const RightContent = styled.div`
  width: 100%;
  background: #FFFFFF;
  //padding: 45px;
  //padding: 65px 105px;
  padding: 40px 14px;
  //border: 1px solid #C0CCDA;
  //border-radius: 20px;
  @media (min-width: 1024px) {
    width: 48%;
    padding: 32px 52px;
  }
`;

export const FormBox = styled.form`
  display: block;
  //padding: 60px 25px;
  background: #FFFFFF;
  box-shadow: 0px 4px 250px rgba(0, 0, 0, 0.04);
  border-radius: 24px;
  width: 100%;
  & > div {
    margin-bottom: 20px;
  }

  //@media (min-width: 1024px) {
  //  width: min(100%, 450px);
  //}
`;



export const UploadButton = styled(Upload)`
  max-width: 450px;
  background: #FFFFFF;
  border: 3px dashed #E5E9F2;
  border-radius: 24px;
  padding: 24px 14px;
  display: flex;
  cursor: pointer;
  & > div > span {
    display: flex;
  }

  & div[class~="ant-upload-list"] {
    display: none;
  }
  @media (min-width: 1024px) {
    padding: 24px;
  }
`;

export const UploadImage = styled.img`
  border-radius: 10px;
  height: 90px;
  width: 90px;
  aspect-ratio: 1/1;

  @media (min-width: 1024px) {
    height: 110px;
    width: 110px;
  }
`

export const UploadBlankImage = styled.div`
  background: #E0E6ED;
  border-radius: 10px;
  height: 110px;
  width: 110px;
`

export const UploadRight = styled.div`
  margin-left: 14px;
`

export const UploadHeader = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.35px;
  color: #111317;
  margin-bottom: 8px !important;
  @media (min-width: 1024px) {
    font-size: 20px;
  }
`

export const UploadSubheader = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: #17151E;
  margin-bottom: 12px !important;
  @media (max-width: 1024px) {
    text-transform: capitalize;
    & > span {
      display: none;
    }
  }
`

export const UploadFooter = styled.div`
  display: flex;
  align-items: center;
`

export const UploadFooterText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1.25px;
  color: #2062F4;
  margin-left: 10px !important;

  @media (max-width: 1024px) {
    & > span {
      display: none;
    }
  }
`

export const SocialButtons = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: flex-start;
    & > button:nth-of-type(odd) {
      margin-right: 24px;
    }
  }
`

export const SocialButton = styled.button`
    display: flex;
    align-items: center;
  background: #F9FAFC;
  border-radius: 10px;
  height: 70px;
  width: 210px;
  border: none;
  cursor: pointer;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 25px;
  & > div > p {
    padding: 0 24px 0 12px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #6E7191;
    cursor: pointer;
  }
  
  & > div {
    display: flex;
    align-items: center;
    flex: 1
  }

  &:disabled {
    cursor: default;
    background-color: #F9FAFC;
    & > div > p {
      cursor: default;
    }
  }
`

export const SubmitButtonContainer = styled.div`
    display: flex;
  justify-content: center;
`
