import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import {
  Container,
  FormBox,
  Body,
  AlreadyHaveAcc,
  BodyHeaderText,
  Top, Content,
} from "./style";
import OntrivInput from "../../components/OntrivInput";
import OntrivButton from "../../components/OntrivButton";
import {signupUser} from "../../api/authAPI";

type Inputs = {
  email: string;
  password: string;
  passwordConfirmation: string;
};

const Signup = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>();
  const [isLoading, setIsLoading] = useState(false);
  // const { onShowAlert } = useContext(NetworkErrorAlertContext);

  const mailformat = /^\w+([._+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;

    // console.log('process.env.REACT_APP_API_URL')
    // console.log(process.env.REACT_APP_API_URL)

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setIsLoading(true);
      // console.log('process.env.REACT_APP_API_URL')
      // console.log(process.env.REACT_APP_API_URL)
      const a = await signupUser({
          email: data.email,
          password: data.password,
      })
    setIsLoading(false);
  };

  return (
    <Container>
      <Top>fff</Top>
      <Body>
        <Content>
          <FormBox onSubmit={handleSubmit(onSubmit)}>
            <BodyHeaderText>Set-up your account</BodyHeaderText>
            <Controller
                name="email"
                control={control}
                rules={{
                  required: true,
                  pattern: mailformat,
                }}
                render={({ field }) => (
                    <OntrivInput
                        label='Email address'
                        type='email'
                        field={field}
                        id="email"
                    />
                )}
            />
            <Controller
                name="password"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                    <OntrivInput
                        label='Password'
                        type='password'
                        field={field}
                    />
                )}
            />
            <Controller
                name="passwordConfirmation"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                    <OntrivInput
                        label='Confirm password'
                        type='password'
                        field={field}
                    />
                )}
            />
            <OntrivButton
                type="submit"
                label="Sign Up"
            />
          </FormBox>
        <AlreadyHaveAcc>
          <p>Already have an account?</p>
          <NavLink to="/login">Sign In</NavLink>
        </AlreadyHaveAcc>
      </Content>
      </Body>
    </Container>
  )

};

export default Signup;
