import styled from "styled-components";
import {NavLink} from "react-router-dom";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    //height: calc(100vh - 120px);
  }
`;

// export const Left = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 100%;
//   flex-direction: column;
//   margin-bottom: 30px;
//   padding: 30px 0;
//   @media (min-width: 1024px) {
//     background-color: #ffffff;
//     margin-right: 24px;
//     border-radius: 10px;
//     width: 300px;
//     //height: 280px;
//     max-height: 380px;
//   }
// `;

export const Left = styled.div`
  display: none;
  @media (min-width: 1024px) {
    display: block;
    width: 200px;
    //max-width: 120px;
    margin-right: 24px;
  }
`;

export const Nav = styled(NavLink)`
  width: 100%;
  text-decoration: none;

  @media (min-width: 776px) {
    display: flex;
    flex-direction: row;
  }
`;

export const BackNav = styled.div`
  padding: 5px 10px;
  display: none;
  @media (min-width: 1024px) {
    margin-left: 10px;
    display: block;
  }
`;

export const NavTitle = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.35px;
  color: #111317;
  margin-bottom: 24px;
  text-decoration: none;
  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const ClientName = styled.p`
  margin-top: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #231F20;
`

export const NoClientName = styled.p`
  margin-top: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: rgba(35, 31, 32, 0.55);
  font-style: italic;
`

export const LeftFooterWrapper = styled.div`
  padding: 0 35px;
  margin-top: 30px;
  width: 100%;
`

export const LeftFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
  width: 100%;
  border-top: 1px solid #F2F2F2;
  padding: 15px 0;
`

export const FooterSide = styled.div`
    width: 48%;
  &:nth-of-type(even) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`

export const FooterHeader = styled.p`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #04004D;
  margin-bottom: 9px;
`

export const FooterSubheader = styled.p`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.04px;
  color: #04004D;
`

export const Content = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 4px 250px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 40px 15px;
  display: flex;
  //justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  
  @media (min-width: 1024px) {
    padding: 45px 64px;
    flex: 1;
    align-items: center;
  }
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
`;

export const BodyHeader = styled.p`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.35px;
  color: #04004D;
  margin-bottom: 40px;
  @media (min-width: 1024px) {
    font-size: 20px;
    margin-bottom: 58px;
  }
`;

export const SocialButtons = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  width: 100%;
  & > button:nth-of-type(odd) {
    margin-right: 24px;
  }
  @media (min-width: 776px) {
    width: 450px;
  }
    //margin-top: 25px;
`

export const SocialButton = styled.button`
    display: flex;
    align-items: center;
  background: #F9FAFC;
  border-radius: 10px;
  height: 70px;
  width: 210px;
  border: none;
  cursor: pointer;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 25px;
  & > div > p {
    padding: 0 24px 0 12px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #6E7191;
    cursor: pointer;
  }
  
  & > div {
    display: flex;
    align-items: center;
    flex: 1
  }
  
  &:disabled {
    cursor: default;
    background-color: #F9FAFC;
    & > div > p {
      cursor: default;
    }
  }
`

export const NoClientImage = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 300px;
  background: linear-gradient(180deg, #6AD5ED 0%, #49A8F8 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  & > p {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: #ffffff;
  }
`

export const ClientImage = styled.img`
  width: 150px;
`
