import styled from "styled-components";

export const Container = styled.div`
  padding: 12px 5px 0 17px;
  width: 48%;
  border: 1px solid #E0E0E0;
  height: 72px;
  border-radius: 20px;
  @media (min-width: 1024px) {
    padding: 36px 10px 0 20px;
    width: 100%;
    height: 150px;
    border-radius: 24px;
  }
`;

export const Title = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
  margin: 0;
  @media (min-width: 1024px) {
    font-size: 15px;
    margin-bottom: 22px;
  }
`;

export const Amount = styled.div`
  display: flex;
  align-items: center;
`;

export const Currency = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: #2062F4;
  margin: 0;
  margin-right: 3px;
  @media (min-width: 1024px) {
    font-size: 34px;
  }
`;

export const Value = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 41px;
  letter-spacing: 0.374px;   
  margin: 0;
  color: #000000;
  @media (min-width: 1024px) {
    font-size: 34px;
  }
`;
