import React, {useEffect, useState} from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './App.css';
import Login from "./pages/Login";
import AppShell from "./components/AppShell";
import Dashboard from "./pages/Dashboard";
import Signup from "./pages/Signup";
import ResetPassword from "./pages/ResetPassword";
import Invoices from "./pages/Invoices";
import Posts from "./pages/Posts";
// import Documents from "./pages/Documents";
import AuthProvider from "./context/AuthContext";
import Settings from "./pages/Settings";
import BusinessAccount from "./pages/BusinessAccount";
import {useAppSelector} from "./app/hook";
import ConnectedAccounts from "./pages/ConnectedAccounts";

function App() {
    const { user } = useAppSelector((state) => state.login);
    const userId = user?.id
    const userFirstLogin = user?.first_login
    return (
      <BrowserRouter>
          <AuthProvider>
          <Routes>
            <Route
                path="/"
                element={userId ? <Navigate replace to="/dashboard" /> : <Login />}
            />
            <Route
                path="/login"
                element={userId ? (userFirstLogin ? <Navigate replace to="/business-accounts" /> : <Navigate replace to="/dashboard" />) : <Login />}
            />
            <Route
                path="/signup"
                element={userId ? <Navigate replace to="/dashboard" /> : <Signup />}
            />
            <Route
                path="/reset"
                element={userId ? <Navigate replace to="/dashboard" /> : <ResetPassword />}
            />
              <Route path="/" element={<AppShell />}>
                  <Route
                      path="posts"
                      element={
                          !userId ? <Navigate replace to="/login" /> : <Posts />
                      }
                  />
                  <Route
                      path="invoices"
                      element={
                          !userId ? <Navigate replace to="/login" /> : <Invoices />
                      }
                  />
                  <Route
                      path="settings"
                      element={
                          !userId ? <Navigate replace to="/login" /> : <Settings />
                      }
                  />
                  <Route
                      path="connected-accounts"
                      element={
                          !userId ? <Navigate replace to="/login" /> : <ConnectedAccounts />
                      }
                  />
                  <Route
                      path="business-accounts"
                      element={
                          !userId ? <Navigate replace to="/login" /> : <BusinessAccount />
                      }
                  />
                  <Route
                      path="dashboard"
                      element={
                          !userId ? <Navigate replace to="/login" /> : <Dashboard />
                      }
                  />
              </Route>
          </Routes>
          </AuthProvider>
      </BrowserRouter>
  );
}

export default App;
