import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import loginSlice from "../pages/Login/loginSlice";
import postSlice from "../pages/Posts/postSlice";
import invoiceSlice from "../pages/Invoices/invoiceSlice";
import accountSlice from "../pages/BusinessAccount/accountSlice";
import dashboardSlice from "../pages/Dashboard/dashboardSlice";

const appReducer = combineReducers({
  login: loginSlice,
  post: postSlice,
  invoice: invoiceSlice,
  account: accountSlice,
  dashboard: dashboardSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "persist/PURGE") {
    storage.removeItem("persist:root");
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
