import styled, { css } from "styled-components";

export const ButtonWrapper = styled.button`
  
  width: ${(props) => props.$width || "100%"};
  font-size: ${(props) => props.$fontSize || "16px"};
  height: ${(props) => props.$height || "56px"};
  border: unset;
  border-radius: 8px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  outline:none;
  background-color: ${(props) => props.$backgroundColor || "rgba(0, 83, 244, 1)"};

  ${(props: {
    $theme: "primary" | "secondary" | "alternate";
    $width: string;
    $fontSize?: string;
    $height?: string;
    $backgroundColor?: string;
  }) => {
  // background-color: ${(props) => props.$backgroundColor || "rgba(0, 83, 244, 1)"}` ;

    if (props.$theme === "primary") {
      return css`
        border-radius: 16px;
        color: #ffffff;
        &:disabled {
          background-color: #f1f1f1;
          color: #717070;
        }
      `;
    } else if (props.$theme === "secondary") {
      return css`
        background-color: #000000;
        color: #ffffff;
        &:disabled {
          background-color: #f1f1f1;
          color: #717070;
        }
      `;
    }
  }}
`;
