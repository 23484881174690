import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { message } from 'antd';
import {NavLink} from "react-router-dom";
import {Spin} from "antd";
import { useSearchParams } from 'react-router-dom'
import {
    BusinessLogoImage,
    Container, Content,
    FormBox,
    Left, LoadingContainer,
    LogoBox,
    RememberForgot,
    RememberMe,
    Right,
    WelcomeMessage,
    WelcomeText
} from "./style";
import OntrivButton from "../../components/OntrivButton";
import OntrivInput from "../../components/OntrivInput";
import {getBusinessColors, signinUser} from "./loginSlice";
import {useAppDispatch, useAppSelector} from "../../app/hook";
import logo from '../../logo.png'

type Inputs = {
    email: string;
    password: string;
};

const Login = () => {
    const [searchParams] = useSearchParams()
    const {
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm<Inputs>();
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingBranding, setIsFetchingBranding] = useState(true);
    const { status, gradientColor, brandColor, businessLogo } = useAppSelector((state) => state.login);

    const mailformat = /^\w+([._+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;

    useEffect(()=>{
        const userUniqueId = searchParams.get('code');
        localStorage.setItem('business_code', userUniqueId)
        if(userUniqueId) {
            (async () => {
                try {
                    const res = await dispatch(getBusinessColors({
                        uniqueId: userUniqueId,
                    })).unwrap();
                } catch (e) {
                    messageApi.open({
                        type: 'error',
                        content: e.error[0] || 'Code invalid',
                        // content: 'Error logging in',
                    });
                }
                setIsFetchingBranding(false)
            })();

        } else {
            setIsFetchingBranding(false)
        }
    },[])

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        setIsLoading(true);

        try {
            const res = await dispatch(signinUser({
                email: data.email,
                password: data.password,
            })).unwrap();
            localStorage.setItem("token", res.access_token);
            messageApi.open({
                type: 'success',
                content: 'Login successful',
            });
            // console.log(res)
        } catch (e) {
            messageApi.open({
                type: 'error',
                content: e?.error[0] || 'Error logging in',
            });
        }
        setIsLoading(false);
    };

    if(isFetchingBranding) {
        return <LoadingContainer><Spin spinning/></LoadingContainer>
    }
    return(
        <Container onSubmit={handleSubmit(onSubmit)}>
            {contextHolder}
            <Spin spinning={status === 'loading' || isLoading}>
                <Content>
            <Right>
                <LogoBox>
                    {businessLogo ? <BusinessLogoImage src={businessLogo} alt="ontriv-logo" width="200px" /> :
                        <img src={logo} alt="ontriv-logo" width="200px" />
                    }
                </LogoBox>
                <FormBox>
                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            required: true,
                            pattern: mailformat,
                        }}
                        render={({ field }) => (
                            <OntrivInput
                                label='Email address'
                                type='email'
                                field={field}
                                id="email"
                            />
                        )}
                    />
                    <Controller
                        name="password"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field }) => (
                            <OntrivInput
                                label='Password'
                                type='password'
                                field={field}
                            />
                        )}
                    />
                    <RememberForgot>
                        <RememberMe>Remember me</RememberMe>
                    </RememberForgot>
                    <OntrivButton
                        type="submit"
                        label="Log In"
                    />
                </FormBox>
            </Right>
                </Content>
            </Spin>
        </Container>
    )
}

export default Login
