import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  border: 1px solid #0053F4;
  border-color: ${(props: {
    $disabled: boolean;
    $height?: string;
  }) => (props.$disabled ? "#F9FAFC" : "#0053F4")};
  border-radius: 16px;
  height: ${(props) => props.$height ?? "64px"};

  :focus-within {
    border: 1px solid #000000;
  }

  &.password_success_style {
    border: 1px solid #a3a3a3;
  }
  &.error,
  &.password_error_style {
    border: 1px solid #f9123b;
  }
  &.error p,
  &.password_error_style p {
    color: #f9123b;
  }

  & input {
    padding: 8px 14px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    border: none;
    outline: none;
  }

  & input::placeholder {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #565c69 !important;
  }

  & input:disabled {
    color: #a3a3a3 !important;
  }
`;

export const ButtonWrapper = styled.button`
  border: unset;
  padding: 3px;
  height: 30px;
  box-sizing: border-box;
  background-color: transparent;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  border-radius: 8px;
  height: 100%;

  & svg {
    margin-right: 10px;
  }
`;
