import styled, {css} from "styled-components";
import {Modal, Table} from "antd";

export const Wrapper = styled(Modal)`
  width: min(80%, 780px);
  //padding: 0 16px;
  
  & p {
    margin-top: 0;
    margin-bottom: 0;
    //margin-block-end: 0;
    //margin-block-start: 0;
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 30px 10px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px !important;
`

export const Logo = styled.img`
    height: 28px;
`

export const InvoiceNumber = styled.p`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 34px;
  line-height: 41px;
  text-align: right;
  letter-spacing: 0.374px;
  color: #2062F4;
`

export const DateAddress = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DateAddressSide = styled.div`
    width: 35%;
`;

export const DateAddressRight = styled.div`
    width: 35%;
  flex-direction: column;
  align-items: flex-end;
  display: flex;
`;

export const BusinessAddressText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: #6F6F84;
  margin-bottom: 14px !important;
`;

export const DateText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #222234;
  margin-bottom: 14px !important;
`;

export const ClientLabel = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: #6F6F84;
`;

export const ClientNameText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  //text-align: right;
  letter-spacing: 0.25px;
  color: #222234;
`;

export const ClientAddressText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  //text-align: right;
  letter-spacing: 0.25px;

  /* Fonts/Primary */

  color: #222234;
`;

export const StatusDiv = styled.div`
  //  display: flex;
  //justify-content: flex-end;
  margin-top: 10px !important;
  width: 100%;
  & > p {
    text-align: right;
  }
`;

export const InvoiceStatus = styled.p`
  text-transform: uppercase;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 20px;
  ${(props: {
    $status: "Overdue" | "Pending" | "Paid";
  }) => {
    if (props.$status === "Overdue") {
      return css`
          color: #FF2154;
      `;
    } else if (props.$status === "Pending") {
      return css`
              color: #ED8000;
      `;
    } else if (props.$status === "Paid") {
      return css`
          color: #57D9A3;
      `;
    }
  }}
`;

export const Divider = styled.div`
  padding-top: 50px;
  border-bottom: 1px solid rgba(157, 168, 182, 0.5);
  margin-bottom: 20px;
`

export const Items = styled.div`
  padding: 5px 35px 35px;
  
  width: 100%;
  background: #FAFAFC;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  margin-bottom: 28px;
`

export const TotalsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const TotalsBox = styled.div`
  padding: 24px 0;
  background: #FAFAFC;
  border-radius: 8px;
  width: min(90%, 460px);
  //width: 100%;
  //background: #FAFAFC;
  //border: 1px solid #E0E0E0;
  //border-radius: 8px;
  //margin-bottom: 28px;
`

export const SubtotalDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 0 35px;
`

export const SubtotalValue = styled.p`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 0.04px;
  color: #222234;
`

export const TotalDiv = styled.div`
  background: #E8F7FF;
  border-radius: 8px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //margin-top: 8px;
  margin: 8px 15px 0 15px;
`

export const TotalLabel = styled.p`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #6F6F84;
`

export const TotalValue = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align: right;
  letter-spacing: 0.36px;
  color: #2062F4;
`


export const ItemsTable = styled(Table)`
  margin-top: 32px;

  & div[class~="ant-table"] div[class~="ant-table-content"] {
    overflow-x: auto;
    overflow-y: hidden;
  }
  
  & * {
    background-color: transparent !important;
  }

  & tbody[class="ant-table-tbody"] > tr > td {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    letter-spacing: 0.04px;
    color: #222234;
  }

  .ant-table-tbody > tr > td {
    padding: 8px 0px;
    padding-right: 24px;
  }

  & thead[class="ant-table-thead"] > tr > th {
    background-color: #ffffff;
    border-bottom: unset;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #6F6F84;
    
    padding: 8px 0;
    padding-right: 24px;
    white-space: nowrap;

    :not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none;
    }
  }

  .ant-table-tbody > tr > td:first-child,
  .ant-table-tbody > tr > td:last-child {
    padding: 8px 16px;
  }

  & thead[class="ant-table-thead"] > tr > th:first-child,
  & thead[class="ant-table-thead"] > tr > th:last-child {
    padding: 8px 16px;
  }

  & thead[class="ant-table-thead"] > tr > th:first-child {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }

  & thead[class="ant-table-thead"] > tr > th:last-child {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }

  &
    tbody[class="ant-table-tbody"]
    > tr
    > td[class~="ant-table-cell"]:last-child
    > div {
    justify-content: flex-end;
  }
`;

export const PayContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

export const PayButton = styled.button`
  height: 40px;
  background: #2062F4;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  width: 150px;

  font-family: 'Poppins';
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 1.25px;
  color: #F7F7FC;
`

export const DownloadFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  //margin-top: 20px;
`

export const DownloadInvoiceButton = styled.button`
  height: 40px;
  background: #ffffff;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  width: 110px;

  font-family: 'Poppins';
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 1.25px;
  //color: #F7F7FC;
  
  & > span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 1.25px;
    color: #2062F4;
    margin-left: 8px;
  }
`

export const PrintButton = styled.button`
  height: 40px;
  background: #2062F4;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  width: 110px;

  font-family: 'Poppins';
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 1.25px;
  color: #F7F7FC;
`
